import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StreamState {
  adminChannels: string[];
}

const initialState: StreamState = {
  adminChannels: ["JeanMassiet", "Hugolisoir", "Kamet0", "Neva"],
};

const streamSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    addAdminChannel: (state, action: PayloadAction<string>) => {
      if (!state.adminChannels.includes(action.payload)) {
        state.adminChannels.push(action.payload);
      }
    },
  },
});

export const { addAdminChannel } = streamSlice.actions;
export default streamSlice.reducer;
