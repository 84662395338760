import "../../styles/SideBar.css";
import SideBarItem from "./SideBarItem";
import { useDispatch, useSelector } from "react-redux";
import { Member } from "../../models/Member";
import { useTranslation } from "react-i18next";

interface SibeBarRoute {
  path: string;
  icon: JSX.Element;
  title: string;
  disable: boolean;
}

const SideBar: React.FC<{ admin?: boolean }> = ({
  admin = false,
  ...props
}) => {
  const userData = useSelector<Record<string, any>, any>((state) => state.user);

  const { t } = useTranslation();

  // console.log("userData", userData.data);

  const SideBarRouter: SibeBarRoute[] = [
    {
      path: "/dashboard/tableau-bord",
      icon: <i className="fa-solid fa-chart-line"></i>,
      title: t("Dashboard"),
      disable: false,
    },
    {
      path: "/dashboard/card",
      icon: <i className="fa fa-credit-card"></i>,
      title: t("Cards"),
      disable: false,
    },
    {
      path: "/dashboard/profile",
      icon: <i className="fa fa-user-check"></i>,
      title: t("Profile"),
      disable: false,
    },
    {
      path: "/dashboard/participation",
      icon: <i className="fa fa-trophy"></i>,
      title: "Participation",
      disable: false,
    },
    {
      path: "/dashboard/member",
      icon: <i className="fa fa-user-group"></i>,
      title: t("Member"),
      disable: false,
    },
    {
      path: "/dashboard/history",
      icon: <i className="fa fa-sync"></i>,
      title: t("History"),
      disable: false,
    },
    {
      path: "/dashboard/team",
      icon: <i className="fa fa-users"></i>,
      title: t("Team"),
      disable: false,
    },
    {
      path: "/dashboard/season",
      icon: <i className="fa fa-calendar-alt"></i>,
      title: t("Season"),
      disable: false,
    },
    {
      path: "/dashboard/transfert",
      icon: <i className="fa-solid fa-table-columns"></i>,
      title: t("Transfert"),
      disable: false,
    },
    {
      path:"/dashboard/stream",
      icon: <i className="fa-solid fa-video"></i>,
      title: t("Stream"),
      disable: false,
    }
  ];

  const applyRouteRestrictions = (routes: SibeBarRoute[], user: Member) => {
    const adminOnlyRoutes = ["/dashboard/tableau-bord", "/dashboard/member"];

    let adminValue = 1;

    // Vérifier si l'utilisateur est un admin
    const isAdmin = user.admin && adminValue.toString() ? true : false;

    const isSuperAdmin =
      user.super_admin && adminValue.toString() ? true : false;

    // Vérifier si l'utilisateur a le statut 'Team'
    const isTeamMember = user.status === "Team";

    return routes.filter((route) => {
      if (isSuperAdmin) {
        return true;
      }

      if (!isAdmin && adminOnlyRoutes.includes(route.path)) {
        return false;
      }

      return true;
    });
  };

  return (
    <div className="SideBar">
      <div className="SideBar__brand py-1 d-flex justify-content-center align-items-center">
        <img alt="E-sports" src={require("../../images/logo.png")} />
      </div>
      <div className="SideBar__navigator d-flex flex-column gap-3">
        {applyRouteRestrictions(SideBarRouter, userData.data).map(
          ({ path, icon, title, disable }) => (
            <SideBarItem
              key={path}
              to={path}
              icon={icon}
              title={title}
              disable={disable}
            />
          )
        )}
      </div>
      <div className="poweredBy">
        {t("Powered by")}{" "}
        <a className="text-yellow" href="https://www.mentalists.ca/">
          Mentalists
        </a>
      </div>
    </div>
  );
};

export default SideBar;
