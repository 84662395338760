import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import { useTranslation } from "react-i18next";
import useForm from "../../hook/useForm";
import { UserService } from "../../services/UserService";
import { FormOptions } from "./AuthForm";

const LoginForm = (options: FormOptions) => {
  const { t } = useTranslation();
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    { phone: "", password: "" },
    (name: string, value: string | any[]) => {
      if (name === "phone") {
        if (!/^6[0-9]\d{7}$/.test(value as string))
          return "incorrect phone number";
      }
      if (name === "password") {
        if (value.length < 6) return "incorrect password";
      }
      return null;
    }
  );

  const __handleSubmit = async (e: any) => {
    e.preventDefault();

    const isSubmittable = handleSubmit(e);

    if (submitBtnRef.current) {
      let innerHTML = submitBtnRef.current.innerHTML;
      submitBtnRef.current.setAttribute("disabled", "true");
      submitBtnRef.current.innerHTML = innerHTML.replace(
        t("Login") as string,
        t("connecting...")
      );

      UserService.signin(formValues)
        .then((json) => {
          if (json.data.errors) {
            setErrors(json.data.errors);
            e.target.removeAttribute("disabled");
            e.target.innerHTML = innerHTML;
          } else {
            new Promise((resolve, reject) => {
              localStorage.clear();
              localStorage.setItem("authToken", json.data.token);
              localStorage.setItem(
                "authTokenDate",
                new Date() as unknown as string
              );
              resolve(localStorage.getItem("authToken"));
            }).then((token) => {
              setTimeout(() => {
                window.location.href = "/dashboard/card";
              }, 500);
            }); // navigate to user account
          }
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.errors) {
            if (data.message && data.message === "inactive") {
              localStorage.setItem("otpConfirmation", "true");
              localStorage.setItem("authToken", data.token);
              localStorage.setItem(
                "authTokenDate",
                new Date() as unknown as string
              );
              window.location.href = "/";
            }

            setErrors((state: any) => {
              return { ...state, ...data.errors };
            });
          }
          e.target.removeAttribute("disabled");
          e.target.innerHTML = innerHTML;
        });
    }
  };

  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);

  return (
    <>
      <form
        id="loginForm"
        className="auth-form login-form d-flex flex-column justify-content-start w-100"
      >
        <h3 className="w-100 text-center">{t("Login")}</h3>
        {errors.length > 0 && (
          <span className="form-error">
            <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
            {errors[0]}
          </span>
        )}
        {errors.phone && (
          <span className="form-error">
            <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
            {errors.phone}
          </span>
        )}
        <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
          <div className="mb-3 d-flex flex-column w-100">
            <label htmlFor="phone" className="col-form-label">
              {t("Phone number")}
            </label>
            <div className="col">
              <input
                onChange={handleChange}
                value={formValues.phone}
                type="phone"
                className="w-100"
                name="phone"
                placeholder={"" + t("Phone number")}
              />
            </div>
          </div>
        </div>

        <div className="mb-3 d-flex flex-column">
          <label htmlFor="password" className="col-form-label">
            {t("Password")}
          </label>
          <div className="col">
            <input
              onChange={handleChange}
              value={formValues.password}
              type="password"
              className="w-100"
              name="password"
              placeholder="******"
            />
          </div>
        </div>

        <button
          onClick={(e) => __handleSubmit(e)}
          ref={submitBtnRef}
          type="submit"
          className="esport-btn-primary esport d-flex justify-content-center gap-2 align-items-center"
        >
          <img src={require("../../images/icons/game.png")} alt="" />
          {t("Login")}
        </button>
        <div className="d-flex justify-content-center mt-3">
          {t("No account")} ?{" "}
          <span
            onClick={() => options.onToggle(".register-form")}
            className="text-green cursor-pointer ms-1"
          >
            {" "}
            {t("Register")}{" "}
          </span>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <span
            onClick={() => options.onToggle(".forgot-form")}
            className="text-green cursor-pointer ms-1"
          >
            {" "}
            {t("Mot de passe oublié")} ?{" "}
          </span>
        </div>
      </form>

      <Modal
        backdrop="static"
        keyboard={false}
        centered
        show={show as boolean}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>E-Sports Gaming 2023</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Dear")} {formValues.first_name},<br />
          <p>{"confirmOTP.part1"}</p>
          <p>{"confirmOTP.part2"}</p>
          <p>{"confirmOTP.part3"}</p>
          {t("confirmOTP.part4")}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="esport px-5"
            variant="secondary"
            onClick={handleClose}
          >
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export { LoginForm };
