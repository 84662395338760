import { AnyAction } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CircularLoader from "../component/loader/CircularLoader";
import Navbar from "../component/navigation/Navbar";
import { Member } from "../models/Member";
import { getMembers, MemberState } from "../redux/slices/memberSlice";
import "../styles/page/ListPage.css";
import { parseReadableDate } from "../utils/utils";
import { COMPETITIONS } from "./MemberPage";

const ListPage = () => {
  const dispatch = useDispatch();
  const memberData = useSelector<Record<string, MemberState>, MemberState>(
    (state) => state.member
  ).data;
  const [q, setQ] = useState<string | null>(null);
  const { t } = useTranslation();

  const navigateInTo = function (this: HTMLElement, ev: MouseEvent): any {
    const targetSelector = this.getAttribute("data-scroll-to");
    if (targetSelector)
      document.querySelector(targetSelector)?.scrollIntoView();
  };

  const memberDataColumns = [
    {
      name: "Profile",
      selector: (row: Member) => {
        if (row.image) {
          return (
            <img
              src={row.image}
              className="p-2"
              style={{ borderRadius: "50%", width: "70px" }}
              alt={row.first_name}
            />
          );
        }
        return (
          <small
            className="text-muted text-white-50 small"
            style={{ userSelect: "none" }}
          >
            ---
          </small>
        );
      },
    },
    {
      name: "Date",
      selector: (row: { created_at: any }) => {
        return parseReadableDate(row.created_at, true, true);
      },
      sortable: true,
      // sortFunction: (a: { created_at: string | number | Date; }, b: { created_at: string | number | Date; }) => {
      //     let aDate = new Date(a.created_at)
      //     let bDate = new Date(b.created_at)
      //     return (aDate as unknown as number) - (bDate as unknown as number);
      // }
    },
    {
      name: t("Last name"),
      selector: (row: { last_name: any }) => {
        return row.last_name;
      },
      sortable: true,
    },
    {
      name: t("First name"),
      selector: (row: { first_name: any }) => {
        return row.first_name;
      },
      sortable: true,
    },
    {
      name: t("City"),
      selector: (row: { city: any }) => {
        return row.city;
      },
      sortable: true,
    },
    {
      name: t("Phone number"),
      selector: (row: { phone: any }) => {
        return row.phone;
      },
      sortable: true,
    },
    // {
    //     name: t('competition'),
    //     selector: (row: { participation: any; }) => {
    //         const participation = row.participation;
    //         if (participation && participation.length > 0) {
    //             const competitions = participation.map((p: any) => p.competition);
    //             return <div className="d-flex flex-column gap-1 my-2">
    //                 {competitions.map((c: any) => <div style={{ width: 'max-content' }} className="rounded border p-1" key={c}>
    //                     {c === 'world cup' ? t('WorldCupCameroon') : t('WorldCupRomania')}
    //                     <i className="fa fa-check-circle"></i></div>)}
    //             </div>
    //         }
    //         return t('nothing')
    //     },
    //     sortable: true,
    //     width: '335px',
    // },
    {
      name: t("certified"),
      selector: (row: { certified: boolean }) => {
        return Badge(row.certified);
      },
    },
  ];

  const Badge = (certified: boolean) => {
    return certified ? (
      <img
        alt="certified"
        style={{ width: "3em" }}
        src={require("../images/icons/badge.png")}
      />
    ) : (
      <i style={{ color: "#F40000" }} className="fa fa-times fa-3x" />
    );
  };

  const customTextFilter = (data: any[], search: string | null) => {
    if (search === null) {
      return data;
    }
    search = search.toLowerCase();
    return data.filter(
      (val: { first_name: string; last_name: string }, key: any) => {
        return (
          val.first_name.toLowerCase().includes(search as string) ||
          val.last_name.toLowerCase().includes(search as string)
        );
      }
    );
  };

  const initialFilter = (members: Member[]) => {
    return members
      .sort((a, b) => {
        let aDate = new Date(a.created_at);
        let bDate = new Date(b.created_at);
        return (aDate as unknown as number) - (bDate as unknown as number);
      })
      .sort((a, b) => {
        const _a = a.image ? 1 : 0;
        const _b = b.image ? 1 : 0;
        return -_a + _b;
      })
      .sort((a, b) => {
        const _a = a.certified ? 1 : 0;
        const _b = b.certified ? 1 : 0;
        return -_a + _b;
      });
  };

  useEffect(() => {
    !memberData && dispatch(getMembers() as unknown as AnyAction);
  }, [memberData, dispatch]);

  return (
    <div className="p-0 m-0">
      <div className="NavbarContainer w-100">
        <div className="custom-container">
          <Navbar />
        </div>
      </div>
      <section id="registrantsList" className="d-flex">
        <div className="custom-container">
          {memberData ? (
            <div
              style={{ marginTop: "140px" }}
              className="HomePage__about__content bounce-animation w-100 p-3"
            >
              <div className="mb-3 d-flex justify-content-between flex-column flex-md-row align-items-center">
                <span className="w-100" style={{ fontSize: "2em" }}>
                  Filtre
                </span>
                <div className="filter d-flex border ms-auto">
                  <div className="searchForm d-flex justify-content-center align-items-center">
                    <input
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setQ(e.target.value)
                      }
                      id="search"
                      type={"text"}
                      placeholder="Rechercher"
                    />
                    <div className="mx-3">
                      <i className="fa fa-search" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3 col-12 col-lg-8 mx-auto">
                <img
                  className="img-fluid"
                  src={require("../images/deadline-for-registration.jpg")}
                  alt=""
                />
              </div>
              <DataTable
                className="CustomDataTable"
                columns={memberDataColumns as TableColumn<Member>[]}
                data={initialFilter(customTextFilter(memberData.slice(), q))}
                striped
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
              />
            </div>
          ) : (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100vh" }}
            >
              <CircularLoader />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export { ListPage };
