import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { UserService } from "../../services/UserService";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;

export interface SeasonState {
  data: any;
  loading: boolean;
  error: string | undefined;
}

const initialState: SeasonState = {
  data: null,
  loading: false,
  error: "",
};

export const addSeason = createAsyncThunk(
  "season/add-season",
  async (data: any) => {
    const res = await UserService.addSeason(data);
    return res.data;
  }
);

const seasonSlice = createSlice({
  name: "season",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addSeason.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default seasonSlice.reducer;
