import { useTranslation } from "react-i18next";
import "../../styles/component/HomeJoin.css";
import Separator from "../Separator";

const HomeJoin = () => {
  const { t } = useTranslation();

  return (
    <div className="HomeJoin rounded mb-5 mt-5">
      <div className="HomeJoin__content row mx-auto p-2 w-100">
        <div className="joinImage col-lg-6 d-flex justify-content-start h-50">
          <img src={require("../../images/games/10.png")} alt="" />
          <img
            className="image-join"
            src={require("../../images/games/port.jpg")}
            alt=""
          />
        </div>
        <div className="col-lg-6 ms-auto mb-2">
          <div className="d-flex justify-content-start gap-4">
            <span className="d-flex gap-2 align-items-center">
              <i className="fa fa-calendar" />
              {t("joinGaming.date")}
            </span>
            <span className="d-flex gap-2 align-items-center">
              <i className="fa fa-map-marker" /> {t("Cameroon")}
            </span>
          </div>
          <h2 className="my-4">{t("joinGaming.title")}</h2>
          <p>{t("joinGaming.part1")}</p>
          <div className="row text-center mx-1">
            <div className="col-6 gamer-rule rounded">
              <h2 className="text-yellow">1</h2>
              <p>{t("joinGaming.part2")}</p>
            </div>
            <div className="col-6 gamer-rule rounded">
              <h2 className="text-yellow">16</h2>
              <p>{t("joinGaming.part3")}</p>
            </div>
            <div className="col-6 gamer-rule rounded">
              <h2 className="text-yellow">2</h2>
              <p>{t("joinGaming.part4")}</p>
            </div>
            <div className="col-6 gamer-rule rounded">
              <h2 className="text-yellow">$ 100k</h2>
              <p>{t("joinGaming.part5")}</p>
            </div>
          </div>
        </div>
        <div className="col-8 ms-auto">
          <Separator />
        </div>
      </div>
    </div>
  );
};

export default HomeJoin;
