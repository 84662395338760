import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { UserService } from "../../services/UserService";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;

export interface RegionPayment {
  id: string;
  region: string;
  total_amount: number;
}

export interface TransfertState {
  data: RegionPayment[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: TransfertState = {
  data: null,
  loading: false,
  error: null,
};

export const getPaymentByRegion = createAsyncThunk(
  "admin/getPaymentByRegion",
  async () => {
    const res = await UserService.getPaymentByRegion();
    return res.data;
  }
);

export const addTransfert = createAsyncThunk(
  "admin/add-transfert",
  async (data: { region: string }) => {
    const res = await UserService.addTransfert(data);
    return res;
  }
);

const transfertSlice = createSlice({
  name: "transfert",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentByRegion.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentByRegion.fulfilled, (state, action: PayloadAction<RegionPayment[]>) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getPaymentByRegion.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      })
      .addCase(addTransfert.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTransfert.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addTransfert.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export default transfertSlice.reducer;
