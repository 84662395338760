import React, { useEffect, useRef, useState } from "react";
import AdComponent from "../ads/AdComponent";
import { Ad, adService } from '../../services/AdService';

interface TwitchPlayerProps {
  channel: string;
  width?: string | number;
  height?: string | number;
  autoplay?: boolean;
  muted?: boolean;
  parent: string[];
}

const TwitchPlayer: React.FC<TwitchPlayerProps> = ({
  channel,
  width = "800",
  height = "450",
  autoplay = true,
  muted = false,
  parent,
}) => {
  const playerRef = useRef<HTMLDivElement | null>(null);
  const twitchPlayerRef = useRef<any>(null);
  const [currentAd, setCurrentAd] = useState<Ad | null>(null);

  const showAd = () => {
    const ad = adService.getAd('inStream');
    setCurrentAd(ad);
    setTimeout(() => setCurrentAd(null), ad.duration! * 1000);
  };

  // Fonction pour créer ou mettre à jour le lecteur Twitch
  const createOrUpdatePlayer = () => {
    if (!twitchPlayerRef.current && (window as any).Twitch) {
      const options = {
        width,
        height,
        channel,
        autoplay,
        muted,
        parent,
      };
      twitchPlayerRef.current = new (window as any).Twitch.Player(
        playerRef.current,
        options
      );


      twitchPlayerRef.current.addEventListener((window as any).Twitch.Player.PLAY, () => {
        // Afficher une publicité toutes les 5 minutes
      
        setInterval(showAd, 300000);
      });
    } else if (twitchPlayerRef.current) {
      twitchPlayerRef.current.setChannel(channel);
    }
  };

  useEffect(() => {
    // Vérifier si le script Twitch est déjà chargé
    const existingScript = document.querySelector(
      'script[src="https://player.twitch.tv/js/embed/v1.js"]'
    );

    // Si le script n'existe pas, le créer
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://player.twitch.tv/js/embed/v1.js";
      script.async = true;
      script.onload = () => {
        if (playerRef.current) {
          createOrUpdatePlayer();
        }
      };
      document.body.appendChild(script);
    } else {
      // Si le script existe déjà, créer ou mettre à jour le lecteur
      if (playerRef.current) {
        createOrUpdatePlayer();
      }
    }

    // Nettoyage lors du démontage du composant
    return () => {
      if (twitchPlayerRef.current) {
        twitchPlayerRef.current.destroy();
        twitchPlayerRef.current = null;
      }
    };
  }, [channel, width, height, autoplay, muted, parent]);

  return (
    <div className="twitch-player-container" style={{ position: 'relative' }}>
      <div
        ref={playerRef}
        id={`twitch-player-${channel}`}
      />
      {currentAd && (
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}>
          <AdComponent type="inStream" />
        </div>
      )}
    </div>
  );
};

export default TwitchPlayer;
