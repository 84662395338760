import { useTranslation } from "react-i18next";

const HomePageMain = () => {
  const { t, i18n } = useTranslation();

  const sponsorsLogo = [
    "../../images/sponsors/CM_ambaFrance.svg.png",
    "../../images/sponsors/Eswf-logo.png",
    "../../images/sponsors/log-ea.png",
    "../../images/sponsors/logo-ifc-fond-bleu.png",
    "../../images/sponsors/logo-orange.png",
  ];

  return (
    <section className="HomePage__main d-flex align-items-end">
      <div className="HomePage__main__videoContainer w-100 h-100 p-0 m-0 position-relative">
        <video autoPlay muted loop>
          <source src={require("../../videos/phygital.mp4")}></source>
        </video>
      </div>
      <div className="HomePage__main__absoluteContent">
        <div
          style={{ marginTop: "0", marginBottom: "0" }}
          className="custom-container mb-2 gap-2 gap-md-3 gap-lg-5 d-flex flex-column justify-content-end gap-3 "
        >
          <div className=" d-flex flex-column flex-lg-row justify-content-between align-items-center">
            <div className="d-flex flex-column justify-content-between gap-4">
              <h1>
                {t("mainTitle.part1")} <br className="d-none d-lg-block" />{" "}
                {t("mainTitle.part2")}{" "}
                <span className="text-yellow">{t("mainTitle.part2_2")}</span>
                {/* <span className="text-yellow"> $20,000</span> {t('mainTitle.part3')}  */}
              </h1>
              <div className="HomePage__main__callToAction d-flex flex-column justify-content-between">
                <p>
                  {t("mainTitle.part4")}
                  <br className="d-none d-md-bock" />
                  <span className="text-yellow">
                    {t("mainTitle.part5")}
                  </span>{" "}
                  {t("mainTitle.part6")}
                  <br /> {t("mainTitle.part7")}
                </p>
                <button
                  data-scroll-to="#authForm"
                  className="esport esport-btn-primary sublimation p-1 px-4"
                >
                  {t("Enrégistres-toi")}
                </button>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column gap-1">
            <div className="HomePage__main__brands d-flex justify-content-between justify-content-md-center w-100 flex-wrap justify-content-md-between align-items-center gap-1 gap-sm-2 gap-md-3">
              <div>
                <img
                  alt="Phygital"
                  src={require(`../../images/sponsors/phygital.png`)}
                />
              </div>
              <div>
                <img
                  alt="Iesf"
                  src={require(`../../images/sponsors/iesf-white.png`)}
                />
              </div>
              <div>
                <img
                  alt="Afro Arab E-sports"
                  src={require(`../../images/sponsors/afro-arab-esports-league.png`)}
                />
              </div>

              <div>
                <img
                  alt="Efiba"
                  src={require(`../../images/sponsors/efiba.png`)}
                />
              </div>
              <div>
                <img
                  alt="Mentalists"
                  src={require(`../../images/sponsors/mentalists.png`)}
                />
              </div>

              <div>
                <img
                  alt="EA"
                  src={require(`../../images/sponsors/log-ea.png`)}
                />
              </div>
              <div>
                <img alt="LTM" src={require(`../../images/sponsors/ltm.png`)} />
              </div>
            </div>
            <img
              data-scroll-to="#about"
              id="navigateDownButton"
              className="go-down-animation shadow-lg align-self-center"
              alt="navigate down"
              src={require("../../images/icons/iconoir_fast-arrow-bottom.png")}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageMain;
