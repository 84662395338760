import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import userReducer from "./slices/userSlice";
import memberReducer from "./slices/memberSlice";
import dashboardReducer from "./slices/dashboardSlice";
import transfertReducer from "./slices/transfertSlice";
import seasonReducer from "./slices/seasonSlice";
import streamReducer from "./slices/streamSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    member: memberReducer,
    dashboard: dashboardReducer,
    transfert: transfertReducer,
    season: seasonReducer,
    stream: streamReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
