import DataTable, { TableColumn } from "react-data-table-component";
import AdminContainer from "../component/AdminContainer";
import { Member } from "../models/Member";
import "../styles/page/MemberPage.css";
import { parseReadableDate } from "../utils/utils";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getMemberRegion,
  getMembers,
  MemberState,
} from "../redux/slices/memberSlice";
import CircularLoader from "../component/loader/CircularLoader";
import { AnyAction } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

export const COMPETITIONS = (key: string) => {
  return key === "tekken7" ? "Tekken 7" : "Street Fighter 5";
};

const MemberPage = () => {
  const dispatch = useDispatch();
  //   const memberData = useSelector<Record<string, MemberState>, MemberState>(
  //     (state) => state.member
  //   ).data;
  const memberRegionData = useSelector<
    Record<string, MemberState>,
    MemberState
  >((state) => state.member).data;
  const [q, setQ] = useState<string | null>(null);
  const { t } = useTranslation();

  const memberDataColumns = [
    {
      name: "Profile",
      selector: (row: Member) => {
        if (row.image) {
          return (
            <img
              src={row.image}
              className="p-2"
              style={{ borderRadius: "50%", width: "70px" }}
              alt={row.first_name}
            />
          );
        }
        return (
          <small
            className="text-muted text-white-50 small"
            style={{ userSelect: "none" }}
          >
            ---
          </small>
        );
      },
    },
    {
      name: "Date",
      selector: (row: { created_at: any }) => {
        return parseReadableDate(row.created_at, true, true);
      },
      sortable: true,
      sortFunction: (
        a: { created_at: string | number | Date },
        b: { created_at: string | number | Date }
      ) => {
        let aDate = new Date(a.created_at);
        let bDate = new Date(b.created_at);
        return (aDate as unknown as number) - (bDate as unknown as number);
      },
    },
    // {
    //     name: 'Nom(s)',
    //     selector: (row: { last_name: any; }) => { return row.last_name },
    //     sortable: true,
    // },
    // {
    //     name: 'Prénom(s)',
    //     selector: (row: { first_name: any; }) => { return row.first_name },
    //     sortable: true,
    // },
    {
      name: t("NickName"),
      selector: (row: Member) => {
        return (
          <span className="text-warning">
            {row.nickname ? `@${row.nickname}` : "---"}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: t("City"),
      selector: (row: Member) => {
        return row.city;
      },
      sortable: true,
    },
    // {
    //     name: t('competition'),
    //     selector: (row: { participation: any; }) => {
    //         const participation = row.participation;
    //         if (participation && participation.length > 0) {
    //             const competitions = participation.map((p: any) => p.competition);
    //             return <div className="d-flex flex-column gap-1 my-2">
    //                 {competitions.map((c: any) => <div style={{ width: 'max-content' }} className="rounded border p-1" key={c}>
    //                     {c === 'world cup' ? t('WorldCupCameroon') : t('WorldCupRomania')}
    //                     <i className="fa fa-check-circle"></i></div>)}
    //             </div>
    //         }
    //         return t('nothing')
    //     },
    //     sortable: true,
    //     width: '335px',
    // },
    // {
    //     name: t('certified'),
    //     selector: (row: { certified: boolean; }) => {
    //         return Badge(row.certified);
    //     },
    //     sortable: true,
    //     sortFunction: (a: Member, b: Member) => {
    //         const _a = a.certified ? 1 : 0
    //         const _b = b.certified ? 1 : 0
    //         return _a - _b;
    //     }
    // },
  ];

  const Badge = (certified: boolean) => {
    return certified ? (
      <img
        alt="certified"
        style={{ width: "3em" }}
        src={require("../images/icons/badge.png")}
      />
    ) : (
      <i style={{ color: "#F40000" }} className="fa fa-times fa-3x" />
    );
  };

  const customTextFilter = (data: Member[], search: string | null) => {
    if (search === null) {
      return data;
    }

    search = search.toLowerCase();
    return data.filter(
      (val: { first_name: string; last_name: string }, key: any) => {
        return (
          val.first_name.toLowerCase().includes(search as string) ||
          val.last_name.toLowerCase().includes(search as string)
        );
      }
    );
  };

  const initialFilter = (members: Member[]) => {
    return members
      .sort((a, b) => {
        let aDate = new Date(a.created_at);
        let bDate = new Date(b.created_at);
        return (aDate as unknown as number) - (bDate as unknown as number);
      })
      .sort((a, b) => {
        const _a = a.image ? 1 : 0;
        const _b = b.image ? 1 : 0;
        return -_a + _b;
      })
      .sort((a, b) => {
        const _a = a.certified ? 1 : 0;
        const _b = b.certified ? 1 : 0;
        return -_a + _b;
      });
  };

  //   useEffect(() => {
  //     !memberData && dispatch(getMembers() as unknown as AnyAction);
  //   }, [memberData, dispatch]);

  useEffect(() => {
    !memberRegionData && dispatch(getMemberRegion() as unknown as AnyAction);
  }, [memberRegionData, dispatch]);

  return (
    <AdminContainer>
      <section
        id="memberPageList"
        className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-2 h-100"
      >
        {memberRegionData ? (
          <>
            <div className="mb-3 d-flex justify-content-between flex-column flex-md-row align-items-center">
              <span className="w-100" style={{ fontSize: "2em" }}>
                {t("Filter")}
              </span>
              <div className="filter d-flex border ms-auto">
                <div className="searchForm d-flex justify-content-center align-items-center">
                  <input
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setQ(e.target.value)
                    }
                    id="search"
                    type={"text"}
                    placeholder={"" + t("Search")}
                  />
                  <div className="mx-3">
                    <i className="fa fa-search" />
                  </div>
                </div>
              </div>
            </div>
            <DataTable
              className="CustomDataTable"
              columns={memberDataColumns as TableColumn<Member>[]}
              data={initialFilter(
                customTextFilter(memberRegionData.slice(), q)
              )}
              striped
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
            />
          </>
        ) : (
          <CircularLoader />
        )}
      </section>
    </AdminContainer>
  );
};

export default MemberPage;
