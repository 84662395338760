import { AnyAction } from "@reduxjs/toolkit";
import { useState, useRef, LegacyRef } from "react";
import { useDispatch } from "react-redux";
import useForm from "../../hook/useForm";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import { Member } from "../../models/Member";
import CropImageModal from "../CropImageModal";
import { avatarUrl } from "../navigation/TopBar";
import "../../styles/component/ProfileForm.css";
import "react-image-crop/dist/ReactCrop.css";
import { updateUser } from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ProfilForm = ({ user }: { user: Member }) => {
  const { t } = useTranslation();

  const [passwordVisibility, handleTogglePasswordVisibility] =
    usePasswordVisibility(false);

    const [formValues, errors, setErrors, handleChange, handleChangeCity, handleSubmit] = useForm(
        { ...user, password: null },
        (name: string, value: string) => {
            if (name === 'last_name') {
                if (value.length < 3) return 'incorrect last name';
            }
            if (name === 'first_name') {
                if (value.length < 3) return 'incorrect first name';
            }
            if (name === 'email') {
                if (value && value.length > 0 && !(value)) return 'incorrect email';
            }
            if (name === 'city') {
                if (value.length < 2) return 'incorrect city';
            }
            if (name === 'address') {
                if (value.length < 4) return 'incorrect address';
            }
            if (name === 'phone') {
                if (!(/^6[0-9]\d{7}$/.test(value as string))) return 'incorrect phone number';
            }
            if (name === 'password' && formValues.password) {
                if (value.length < 7) return 'incorrect password';
            }
            return null;
        }
    );
    // console.log(handleSubmit)
    const [isCropImage, setIsCropImage] = useState(false);
    const [cropData, setCropData] = useState({ save: null, load: null });
    const [image, setImage] = useState<string | null>(null);

  const avatarRef = useRef<HTMLInputElement>();
  const formRef = useRef<HTMLFormElement>();

  const submitData = useRef<HTMLButtonElement>(null);

  const dispatch = useDispatch();

  const __handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const isSubmittable = handleSubmit(e);
    if (submitData.current) {
      e.target.setAttribute("disabled", "true");
      e.target.innerHTML =
        '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';

      const data = new FormData(formRef.current);
      if (!data.get("email")) data.delete("email");
      if (!formValues.password) data.delete("password");
      if ((cropData.save as any)?.blob) {
        data.append("image", (cropData.save as any).blob);
      } else {
        data.delete("image");
      }

      dispatch(updateUser(data) as unknown as AnyAction)
        .unwrap()
        .then((response: any) => {
          if (response.errors) {
            setErrors(response.errors);
          } else {
            toast.success(t("Your profile has been updated"));
          }
        })
        .catch((err: any) => {
          const errors = err;
          console.log(errors);
          // toast.error('Une erreur est survenue.')
        })
        .finally(() => {
          e.target.removeAttribute("disabled");
          e.target.innerHTML = t("Save");
        });
    } else {
      console.log(formValues);
    }
  };

  const handleChangeAvatar = (e: any) => {
    if (avatarRef.current) avatarRef.current.click();
  };

  const resizeImage = (
    file: Blob,
    maxWidth: number,
    maxHeight: number,
    callback: { (url: string): void; (arg0: string): void }
  ) => {
    var img = new Image();

    img.onload = function () {
      var width = img.width;
      var height = img.height;

      // Calculate the new image dimensions
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Create a canvas and draw the resized image onto it
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      if (ctx) ctx.drawImage(img, 0, 0, width, height);

      // Get the data URL of the resized image
      var dataUrl = canvas.toDataURL("image/jpeg");
      callback(dataUrl);
    };

    // Load the input file as a data URL
    var reader = new FileReader();
    reader.onload = function (this: FileReader, ev: ProgressEvent<FileReader>) {
      img.src = ev.target?.result as string;
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (e: any) => {
    resizeImage(e.target.files[0], 1500, 500, (url: string) => {
      setImage(url);
      setIsCropImage(true);
    });
  };

  return (
    <div className="ProfileForm bounce-animation rounded col-12 col-md-10">
      <form
        ref={formRef as LegacyRef<HTMLFormElement>}
        className="p-2 m-2 py-4"
      >
        <div className="d-flex align-items-center justify-content-center mb-3">
          <img
            alt="avatar"
            onClick={handleChangeAvatar}
            src={(cropData.save as any)?.base64 ?? user.image ?? avatarUrl}
            className="avatarIMG"
          />
          <i onClick={handleChangeAvatar} className="fa fa-camera"></i>
          <input
            onChange={handleInputChange}
            ref={avatarRef as LegacyRef<HTMLInputElement>}
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            hidden
          />
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <input
                    value={formValues.last_name}
                    onChange={handleChange}
                    type="text"
                    name="last_name"
                    className="w-100"
                    placeholder={"Last name"}
                  />
                  {errors.last_name && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {errors.last_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <input
                    value={formValues.first_name}
                    onChange={handleChange}
                    type="text"
                    className="w-100"
                    name="first_name"
                    placeholder={"First name"}
                  />
                  {errors.first_name && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {errors.first_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <input
                value={formValues.email}
                type="email"
                onChange={handleChange}
                className="w-100"
                name="email"
                placeholder={"Email"}
              />
              {errors.email && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.email}
                </span>
              )}
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <input
                    value={formValues.city}
                    onChange={handleChange}
                    type="text"
                    className="w-100"
                    name="city"
                    placeholder={"City"}
                  />
                  {errors.city && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {errors.city}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="mb-3">
                  <input
                    value={formValues.nickname}
                    onChange={handleChange}
                    type="text"
                    className="w-100"
                    name="nickname"
                    placeholder={"nickname"}
                  />
                  {errors.nickname && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {errors.nickname}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="mb-3 d-flex flex-column w-100">
              {/* <label htmlFor="phone" className="col-form-label required">Téléphone</label> */}
              <div className="col">
                <input
                  onChange={handleChange}
                  value={formValues.phone}
                  type="phone"
                  className="w-100"
                  name="phone"
                  placeholder="Téléphone"
                />
              </div>
              {errors.phone && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {errors.phone}
                </span>
              )}
            </div>

            <div className="input-group">
              <input
                autoComplete="new-password"
                value={formValues.password}
                onChange={handleChange}
                type={passwordVisibility ? "text" : "password"}
                className="w-100"
                name="password"
                placeholder={"Password"}
              />
              {/* <span className="input-group-text" id="togglePasswordVisibility"><i className='fa fa-eye'></i></span> */}
            </div>
            {errors.password && (
              <span className="form-error mb-3">
                <i
                  style={{ fontSize: ".8em" }}
                  className="fa fa-info-circle"
                ></i>{" "}
                {errors.password}
              </span>
            )}
          </div>
        </div>
        <div className="col-12 mt-3 mt-md-2 d-flex justify-content-center align-items-center">
          <button
            onClick={__handleSubmit}
            ref={submitData}
            type="submit"
            className="esport col-12 col-md-6 mx-auto esport-btn-normal"
          >
            {t("Save")}
          </button>
        </div>
      </form>

      {isCropImage && (
        <CropImageModal
          open={isCropImage}
          image={image ?? ""}
          onSave={(data) => {
            setIsCropImage(false);
            setCropData((value: any) => {
              return { ...value, save: data };
            });
          }}
          onClose={() => setIsCropImage(false)}
        />
      )}
    </div>
  );
};

export default ProfilForm;
