import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "./page/HomePage";
import { ListPage } from "./page/ListPage";
import CardPage from "./page/CardPage";
import ProfilePage from "./page/ProfilePage";
import ParticipationPage from "./page/ParticipationPage";
import PaymentPage from "./page/PaymentPage";
import MemberPage from "./page/MemberPage";
import HistoryPage from "./page/HistoryPage";
import "./utils/i18n";
import { AboutPage } from "./page/AboutPage";
import TeamPage from "./page/TeamPage";
import DashboardPage from "./page/DashboardPage";
import SeasonPage from "./page/SeasonPage";
import TransfertPage from "./page/TransfertPage";
import StreamPage from "./page/StreamPage";
import StreamViewPage from "./page/StreamViewPage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// default axios configs
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;
axios.defaults.withCredentials = true;

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/list-of-registrants",
    element: <ListPage />,
  },
  {
    path: "/actuality",
    element: <AboutPage />,
  },
  {
    path: "/streaming",
    element: <StreamViewPage />,
  },
  {
    path: "/dashboard",
    children: [
      {
        path: "tableau-bord",
        element: <DashboardPage />,
      },
      {
        path: "card",
        element: <CardPage />,
      },
      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "participation",
        element: <ParticipationPage />,
      },
      {
        path: "payment",
        element: <PaymentPage />,
      },
      {
        path: "member",
        element: <MemberPage />,
      },
      {
        path: "history",
        element: <HistoryPage />,
      },
      {
        path: "team",
        element: <TeamPage />,
      },
      {
        path: "season",
        element: <SeasonPage />,
      },
      {
        path: "transfert",
        element: <TransfertPage />,
      },
      {
        path: "stream",
        element: <StreamPage />,
      },
    ],
  },
]);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
