// action.ts
export const fetchAccessToken = async (
  clientId: string,
  clientSecret: string
): Promise<string | null> => {
  try {
    const response = await fetch(
      `https://id.twitch.tv/oauth2/token?client_id=${clientId}&client_secret=${clientSecret}&grant_type=client_credentials`,
      { method: "POST" }
    );
    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error("Failed to fetch access token:", error);
    return null;
  }
};

export const fetchLiveStreams = async (
  adminChannels: string[],
  accessToken: string
): Promise<string[]> => {
  try {
    const loginParams = adminChannels
      .map((channel) => `user_login=${channel}`)
      .join("&");

    const response = await fetch(
      `https://api.twitch.tv/helix/streams?${loginParams}`,
      {
        headers: {
          "Client-ID": process.env.CLIENT_ID as string,
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const data = await response.json();

    return data.data.map((stream: any) => stream.user_name);
  } catch (error) {
    console.error("Erreur lors de la vérification des streams:", error);
    return [];
  }
};
