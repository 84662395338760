import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../component/navigation/Navbar";
import "../styles/StreamView.css";
import "../styles/page/ListPage.css";
import TwitchPlayer from "../component/stream/TwitchPlayer";
import { fetchAccessToken } from "../utils/action";
import AdComponent from "../component/ads/AdComponent";
import { useLiveStreams } from "../hook/useLiveStreams";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const StreamViewPage: React.FC = () => {
  const adminChannels = useSelector(
    (state: RootState) => state.stream.adminChannels
  );
  const { liveChannels, isLoading, error } = useLiveStreams(adminChannels);
  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await fetchAccessToken(
          process.env.CLIENT_ID as string,
          process.env.CLIENT_SECRET as string
        );
        setAccessToken(token as string);
      } catch (err) {
        console.error("Erreur lors de la récupération du token d'accès", err);
      }
    };

    getToken();
  }, []);

  if (isLoading) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="loading-container"
      >
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="loading-spinner"
        />
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          Chargement des streams...
        </motion.p>
      </motion.div>
    );
  }

  if (error) {
    return <div>Erreur : {error}</div>;
  }

  return (
    <div className="p-0 m-0">
      <div className="NavbarContainer w-100">
        <div className="custom-container">
          <Navbar />
        </div>
      </div>
      <section className="HomePage__about d-flex" style={{ width: "100%" }}>
        <div className="custom-container" style={{ marginTop: "140px" }}>
          <div className="HomePage__about__content mb-5 w-100 fixe d-height d-flex flex-column p-3 mt-5 justify-content-between align-items-start">
            <div className="w-100 d-flex">
              <div className="stream-container" style={{ flex: "3" }}>
                <h1>Streaming en direct</h1>
                {liveChannels.length > 0 ? (
                  <AnimatePresence>
                    <motion.div
                      className="stream-grid"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      {liveChannels.map((channel, index) => (
                        <React.Fragment key={channel}>
                          <motion.div
                            className="stream-item"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ delay: index * 0.1 }}
                          >
                            <TwitchPlayer
                              channel={channel ?? "Hugolisoir"}
                              width="100%"
                              height={400}
                              parent={[
                                process.env.PARENT_DOMAIN ?? "Mentalists.ca",
                              ]}
                            />
                            <p>{channel}</p>
                          </motion.div>
                          {(index + 1) % 3 === 0 && (
                            <AdComponent type="inStream" />
                          )}
                        </React.Fragment>
                      ))}
                    </motion.div>
                  </AnimatePresence>
                ) : (
                  <div className="no-data-container">
                   <p>Aucun stream en direct pour le moment.</p>                  
                  </div>
                )}
              </div>
              <motion.div
                className="ad-sidebar"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ flex: "1" }}
              >
                <AdComponent type="sidebar" />
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <div className="bottom-ad">
        <AdComponent type="banner" />
      </div>
    </div>
  );
};

export default StreamViewPage;
