import React from 'react';
import { motion } from 'framer-motion';
import { adService } from '../../services/AdService';

interface AdComponentProps {
  type: 'sidebar' | 'banner' | 'inStream';
  onClose?: () => void;
}

const AdComponent: React.FC<AdComponentProps> = ({ type, onClose }) => {
  const ad = adService.getAd(type);

  const handleClick = () => {
    adService.trackAdClick(ad.id);
    if (ad.link) {
      window.open(ad.link.url, '_blank');
    }
  };

  return (
    <motion.div
      className={`ad-container ${type}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3 className="ad-title">{ad.title}</h3>
      {ad.media && (
        ad.media.type === 'image' ? (
          <img src={ad.media.url} alt={ad.media.alt || ad.title} onClick={handleClick} />
        ) : (
          <video src={ad.media.url} controls onClick={handleClick} />
        )
      )}
      <p>{ad.content}</p>
      {ad.link && (
        <button className="ad-cta" onClick={handleClick}>{ad.link.text}</button>
      )}
      {type === 'inStream' && onClose && (
        <button className="ad-close" onClick={onClose}>Fermer</button>
      )}
    </motion.div>
  );
};

export default AdComponent;
