import { useTranslation } from "react-i18next";
import { ImageButton } from "../button/ImageButton";
import Separator from "../Separator";

const HomePageAbout = ({
  onOpenImageViewer,
}: {
  onOpenImageViewer: (index: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <section
      style={{ width: "100%" }}
      id="about"
      className="section-about HomePage__about d-flex flex-column justify-content-center align-items-center"
    >
      <div
        style={{ width: "85%" }}
        className="HomePage__about__content mb-5  fixe d-height d-flex flex-column p-3 mt-5 justify-content-between align-items-start"
      >
        <div
          style={{ height: "100%", overflow: "hidden", overflowY: "auto" }}
          className="d-flex w-100 flex-column scrollbar"
        >
          <h3
            className="text-primary"
            dangerouslySetInnerHTML={{
              __html: t("Communique.title").replace(
                "BLAISE DIBOTO",
                '<span class="selfName">BLAISE DIBOTO</span>'
              ),
            }}
          ></h3>
          <Separator />
        </div>
        <div className="">
          <p>
            {/* {t("Communique.title")} */}
            {t("Communique.part1.p1")}
            {t("Communique.part1.p2")}
            {t("Communique.part1.p3")}
          </p>
          <p>{t("Communique.part1.p4")}</p>
          <p>{t("Communique.part1.p5")}</p>
        </div>
      </div>
      <div className="custom-container">
        <div className="d-flex w-100 mb-4">
          <img
            onClick={() => {
              onOpenImageViewer(34);
            }}
            className="img-fluid ImgTop col-2"
            src={require("../../images/last-editions/34.jpg")}
            alt=""
          />
          <img
            onClick={() => {
              onOpenImageViewer(35);
            }}
            className="img-fluid col-4"
            src={require("../../images/last-editions/35.jpg")}
            alt=""
          />
          <img
            onClick={() => {
              onOpenImageViewer(36);
            }}
            className="img-fluid col-4"
            src={require("../../images/last-editions/36.jpg")}
            alt=""
          />
          <img
            onClick={() => {
              onOpenImageViewer(37);
            }}
            className="img-fluid col-2"
            src={require("../../images/last-editions/37.jpg")}
            alt=""
          />
        </div>
        <div className="HomePage__about__content w-100 fixe d-height d-flex flex-column p-3 justify-content-between align-items-start">
          <div
            style={{ height: "100%", overflow: "hidden", overflowY: "auto" }}
            className="d-flex flex-column scrollbar"
          >
            <div>
              <h4 className="text-primary">{t("Découvrir")}</h4>
              <h4>{t("A propos de Cameroun E-SPORTS")}</h4>
              <span className="title-second">{t("esportsWho")}</span>
              <Separator />
            </div>
            <section>
              <p>{t("mainDescription.part1")}</p>
              <p>{t("mainDescription.part2")}</p>
              <p>{t("mainDescription.part3")}</p>
              <p>{t("mainDescription.part4")}</p>

              <div className="d-flex gap-1">
                <ImageButton
                  className="img-fluid"
                  onClick={() => {
                    onOpenImageViewer(3);
                  }}
                  image={require("../../images/last-editions/3.jpeg")}
                />
                <ImageButton
                  className="img-fluid"
                  onClick={() => {
                    onOpenImageViewer(6);
                  }}
                  image={require("../../images/last-editions/6.jpeg")}
                />
                <ImageButton
                  className="img-fluid"
                  onClick={() => {
                    onOpenImageViewer(8);
                  }}
                  image={require("../../images/last-editions/8.jpeg")}
                />
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageAbout;
