import React, { useState } from "react";
import TwitchPlayer from "../component/stream/TwitchPlayer";
import AdminContainer from "../component/AdminContainer";
import { useTranslation } from "react-i18next";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { addAdminChannel } from "../redux/slices/streamSlice";

function StreamPage() {
  const [showModal, setShowModal] = useState(false);
  const [newChannel, setNewChannel] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const adminChannels = useSelector((state: RootState) => state.stream.adminChannels);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newChannel.trim()) {
      dispatch(addAdminChannel(newChannel.trim()));
      setNewChannel("");
      handleCloseModal();
    }
  };

  return (
    <AdminContainer>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ height: "150px" }}
      >
        <h1 className="text-primary">StreamPage Live</h1>
        <button
          className="esport esport-btn-primary sublimation p-1 px-4 text-uppercase"
          onClick={handleOpenModal}
        >
          <i className="fa fa-plus"></i>
          {t("Add a new stream")}
        </button>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        {/* j'aimerais afficher la liste des channels admin dans un tableau genre on affiche  */}
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Add a new Twitch channel")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{t("Twitch username")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Enter Twitch username") as string}
                value={newChannel}
                onChange={(e) => setNewChannel(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("Close")}
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {t("Add channel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </AdminContainer>
  );
}

export default StreamPage;
