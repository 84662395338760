import * as React from "react";

interface CardDashbordProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

/**
 * j'ai creer une application qui  permet au personne de s'inscrire en tant que jouer et qui peuvent pqyer des carte membre pour pouvoir jouer a des jeux en ligne, il yq plusieurs type d'utilisateur : il y a les super admin, les admin qui
 *
 *
 *j'ai un probleme avec mon code j'aimerai que lorsqu'un utilisateur veut s'inscrire et que lorsqu'il choisit sa ville ce qui est envoye cote backend c'est le nom de la ville et la region et le pays

 voici mon code ""

 voici  mon custom hook useForm ""
 *
 
 *
 *
 */

const CardDashbord: React.FunctionComponent<CardDashbordProps> = (props) => {
  return (
    <div className="card-dashbord tinnycards rounded small text-muted p-1">
      <span>{props.icon}</span>
      <h4 className="text-white">{props.title}</h4>
      <p className="text-primary card-text">{props.description}</p>
    </div>
  );
};

export default CardDashbord;
