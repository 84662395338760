import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Member } from "../../models/Member";
import { UserService } from "../../services/UserService";
import { updatePayment } from "./paymentSlice";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;

export interface UserState {
  // The user state is an object containing the current user's data, or null if the user is not logged in
  data: Member | null;
  loading: boolean;
  error: string | null;
}

const initialState: UserState = {
  data: null,
  loading: false,
  error: null,
};

export const getCurrentUser = createAsyncThunk("user/current", async () => {
  const res = await UserService.getCurrentUser();
  return res.data as Member;
});

export const getDashboardInfo = createAsyncThunk("user/dashboard", async () => {
  const res = await UserService.getDashboardInfo();
  return res.data;
});

export const updateUser = createAsyncThunk("user/update", async (user: any) => {
  const res = await UserService.updateUser(user);
  return res.data as Member;
});

export const generateUserCard = createAsyncThunk(
  "user/update",
  async (user: any) => {
    const res = await UserService.generateCard(user);
    return res.data as Member;
  }
);

export const addParticipation = createAsyncThunk(
  "user/participation/add",
  async (data: { message: string; game: string }) => {
    const res = await UserService.addParticipation(data);
    return res.data as Member;
  }
);

export const logoutCurrentUser = createAsyncThunk("user/logout", async () => {
  return null;
});

export const setCurrentUser = createAsyncThunk(
  "user/connect",
  async (user: Member) => {
    return user;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      // .addCase(updateUser.fulfilled, (state, action) => {
      //   if (action.payload.id) {
      //     state.data = action.payload;
      //   }
      // })
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(generateUserCard.fulfilled, (state, action) => {
        if (action.payload.id) {
          state.data = action.payload;
        }
      })
      .addCase(addParticipation.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(logoutCurrentUser.fulfilled, (state) => {
        state.data = null;
      })
      .addCase(updatePayment.fulfilled, (state, action) => {
        state.data = action.payload.user;
      });
  },
});

export default userSlice.reducer;
