/**
 * Return an array with objects containing data of sample images.
 *
 * @param length - Optional. A number of slides.
 * @param sig    - Optional. The signature for getting a different image.
 *
 * @return An array with objects for sample images.
 */

export function generateSlides(
  length = 10,
  sig = 0
): Array<{ src: string; alt: string }> {
  const regions = [
    "adamaoua.jpg",
    "centre.jpg",
    "est.jpg",
    "extreme-nord.jpg",
    "littoral.jpg",
    "nord.jpg",
    "nord-ouest.jpg",
    "ouest.jpg",
    "sud.jpg",
    "sud-ouest.jpg",
  ];

  return Array.from({ length }).map((value, index) => {
    index = sig || index;
    const imageName = regions[index] || regions[0];
    return {
      src: require(`../images/region/${imageName}`),
      alt: `Image ${index + 1}`,
    };
  });
}
