import React, { LegacyRef, useRef, useState } from "react";
import AdminContainer from "../component/AdminContainer";
import { useTranslation } from "react-i18next";
import useForm from "../hook/useForm";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { avatarUrl } from "../component/navigation/TopBar";
import { useDispatch } from "react-redux";
import { addSeason } from "../redux/slices/seasonSlice";
import { AnyAction } from "@reduxjs/toolkit";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from "framer-motion";
import "../styles/Season.css";

const SeasonPage = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const avatarRef = useRef<HTMLInputElement>();
  const [isCropImage, setIsCropImage] = useState(false);
  const [cropData, setCropData] = useState({ save: null, load: null });
  const [image, setImage] = useState<string | null>(null);
  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    {
      name: "",
      description: "",
      date_from: "",
      date_to: "",
    },
    (name: string, value: string | any[]) => {
      if (name === "name") {
        if (value.length < 3) return "incorrect name";
      }
      if (name === "description") {
        if (value.length < 3) return "incorrect description";
      }
      return null;
    }
  );
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const dispatch = useDispatch();

  const handleOpenModal = (e: any) => {
    setShowModal(true);
  };
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const handleChangeAvatar = (e: any) => {
    if (avatarRef.current) avatarRef.current.click();
  };

  const resizeImage = (
    file: Blob,
    maxWidth: number,
    maxHeight: number,
    callback: { (url: string): void; (arg0: string): void }
  ) => {
    var img = new Image();

    img.onload = function () {
      var width = img.width;
      var height = img.height;

      // Calculate the new image dimensions
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Create a canvas and draw the resized image onto it
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      if (ctx) ctx.drawImage(img, 0, 0, width, height);

      // Get the data URL of the resized image
      var dataUrl = canvas.toDataURL("image/jpeg");
      callback(dataUrl);
    };

    // Load the input file as a data URL
    var reader = new FileReader();
    reader.onload = function (this: FileReader, ev: ProgressEvent<FileReader>) {
      img.src = ev.target?.result as string;
    };
    reader.readAsDataURL(file);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      resizeImage(e.target.files[0], 1500, 500, (url: string) => {
        setImage(url);
        setPreviewImage(url);
      });
    }
  };
  // const handleChangeAvatar = (e: any) => {
  //   if (avatarRef.current) avatarRef.current.click();
  // };

  const handlerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      const formData = new FormData();

      Object.entries(formValues).forEach(([key, value]) => {
        formData.append(key, value as string);
      });

      if (image) {
        const response = await fetch(image as string);
        const blob = await response.blob();
        formData.append('image', blob, 'image.jpg');
      }

      await dispatch(addSeason(formData) as unknown as AnyAction);
      setShowModal(false);
    } catch (error) {
      console.error("Error adding season:", error);
    } finally {
      setSubmitting(false);
    }
  };


  return (
    <AdminContainer>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-4 h-100"
      >
        <div className="d-flex justify-content-between align-items-center">
          <motion.h1
            className="text-primary"
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            Manage your Season
          </motion.h1>
          <motion.button
            className="esport esport-btn-primary sublimation p-1 px-4 text-uppercase"
            onClick={handleOpenModal}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <i className="fa fa-plus"></i>
            {t("Add a new season")}
          </motion.button>
        </div>
      </motion.div>

      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Modal
              style={{ backdropFilter: "blur(2px)", backgroundColor: "#00000040" }}
              backdrop="static"
              keyboard={false}
              show={showModal}
              onHide={() => setShowModal(!showModal)}
            >
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", damping: 15 }}
              >
                <ModalHeader>{t("Add a new season")}</ModalHeader>
                <ModalBody>
                  <form
                    id="registerForm"
                    className="auth-form register-form auth-form-show d-flex flex-column justify-content-start w-100"
                    onSubmit={handlerSubmit}
                  >
                    <div className="d-flex  flex-column flex-md-row gap-3">
                      <div className="mb-3 d-flex flex-column w-100">
                        <label htmlFor="name" className="col-form-label required">
                          {t("Name")}
                        </label>
                        <div className="col">
                          <input
                            onChange={handleChange}
                            value={formValues.name}
                            type="text"
                            className="w-100"
                            name="name"
                            placeholder={"" + t("name")}
                          />
                        </div>
                        {errors.name && (
                          <span className="form-error">
                            <i
                              style={{ fontSize: ".8em" }}
                              className="fa fa-info-circle"
                            ></i>{" "}
                            {errors.name}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                      <div className="mb-3 d-flex flex-column w-100">
                        <label
                          htmlFor="description"
                          className="col-form-label required"
                        >
                          {t("Description")}
                        </label>
                        <div className="col">
                          <textarea
                            onChange={handleChange}
                            name="description"
                            id="description"
                            placeholder={"" + t("add description")}
                            className="w-100"
                          ></textarea>
                        </div>
                        {errors.description && (
                          <span className="form-error">
                            <i
                              style={{ fontSize: ".8em" }}
                              className="fa fa-info-circle"
                            ></i>{" "}
                            {errors.description}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                      <div className="mb-3 pt-2 col-12 col-md-6">
                        <label htmlFor="date_from">Date de début</label>
                        <input
                          onChange={handleChange}
                          required
                          name="date_from"
                          type="datetime-local"
                          className="form-control"
                          id="date_from"
                          value={formValues.date_from}
                        />
                      </div>
                      <div className=" mb-3 pt-2 col-12 col-md-6">
                        <label htmlFor="date_to">Date de fin</label>
                        <input
                          onChange={handleChange}
                          required
                          name="date_to"
                          type="datetime-local"
                          className="form-control"
                          id="date_to"
                          value={formValues.date_to}
                        />
                      </div>
                    </div>
                    <div className="image-upload-container mb-3">
                      {previewImage ? (
                        <div className="preview-image-container">
                          <img
                            src={previewImage}
                            alt="Preview"
                            className="preview-image"
                          />
                          <button 
                            type="button" 
                            className="remove-image-btn"
                            onClick={() => setPreviewImage(null)}
                          >
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                            {t("Remove Image")}
                          </button>
                        </div>
                      ) : (
                        <div 
                          className="upload-placeholder"
                          onClick={handleChangeAvatar}
                        >
                          <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" />
                          <p>{t("Click to upload image")}</p>
                        </div>
                      )}
                      <input
                        onChange={handleInputChange}
                        ref={avatarRef as LegacyRef<HTMLInputElement>}
                        type="file"
                        accept="image/png,image/jpg,image/jpeg"
                        hidden
                      />
                    </div>
                  </form>
                </ModalBody>
                <ModalFooter>
                  <motion.button
                    disabled={submitting}
                    className="esport esport-btn-primary sublimation p-1 px-4"
                    onClick={() => setShowModal(false)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {t("Cancel")}
                  </motion.button>
                  <motion.button
                    disabled={submitting}
                    ref={submitBtnRef}
                    type="submit" 
                    className="esport esport-btn-primary sublimation p-1 px-4"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {submitting ? t("saving...") : t("Add")}
                  </motion.button>
                </ModalFooter>
              </motion.div>
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
    </AdminContainer>
  );
};

export default SeasonPage;
