import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { UserService } from "../../services/UserService";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;

export interface DashBoardInfoState {
  data: any;
  loading: boolean;
  error: string | undefined;
}

const initialState: DashBoardInfoState = {
  data: null,
  loading: false,
  error: "",
};

export const getDashboardInfo = createAsyncThunk(
  "admin/dashboard-info",
  async () => {
    const res = await UserService.getDashboardInfo();
    console.log("data from slice", res.data);
    return res;
  }
);

export const addTransfert = createAsyncThunk(
  "admin/add-transfert",
  async (data: any) => {
    const res = await UserService.addTransfert(data);
    return res;
  }
);

export const addSeason = createAsyncThunk(
  "admin/add-season",
  async (data: any) => {
    const res = await UserService.addSeason(data);
    return res.data;
  }
);

export const getPaymentByRegion = createAsyncThunk(
  "admin/getPaymentBYregion",
  async () => {
    const res = await UserService.getPaymentByRegion();
    return res.data;
  }
);

const dashboardSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardInfo.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(getDashboardInfo.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getDashboardInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder.addCase(getPaymentByRegion.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(addTransfert.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(addSeason.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
