import { title } from "process";

export const resources = {
  en: {
    translation: {
      // Navbar
      Accueil: "Home",
      Actuality: "Actuality",
      About: "About",
      Auth: "Register/Login",
      "Liste des inscrits": "Registrants",
      "Enrégistres-toi": "Register now",

      // About Page
      aboutDescription: {
        title:
          "BLAISE DIBOTO, VISIONARY AND PASSIONATE, ELECTED PRESIDENT OF THE WORLD FEDERATION OF COMBAT E-SPORTS",
        part1:
          "In a groundbreaking announcement in the world of E-Sports, Blaise Diboto, the dynamic President of the Cameroonian Esport Association, has been appointed as the President of the prestigious World Federation of Combat E-Sports. This appointment signifies a monumental victory for the Cameroonian and African gaming community, as well as for the Federation, which will benefit greatly from Blaise's extensive expertise in Public Relations and Event Communication.",
        part2:
          "Excitement is palpable within the Federation as we eagerly anticipate the accomplishments that Blaise will achieve in his new role. His humble and sharing nature, coupled with his unwavering dedication to advancing E-Sports, are undeniable. With his unparalleled expertise, Blaise is poised to propel combat E-Sports to new heights.",
        part3:
          "His appointment as the head of the World Federation of Combat E-Sports is a clear testament to the global growth of this discipline. E-Sports has emerged as an unstoppable force in the entertainment landscape, attracting millions of spectators and offering lucrative opportunities for emerging talents.",
        part4:
          "As the President of the Cameroonian Esport Association, Blaise Diboto has demonstrated his ability to bring together and inspire a community of passionate gamers. Under his leadership, the Cameroonian Esport Association has experienced unprecedented expansion, providing a world-class platform, specifically a digital application (https://cameroon-esports.com/ which you can visit), and exciting competition opportunities for young talents. The combination of his leadership skills and his deep understanding of Public Relations and Event Communication makes him the perfect choice to lead the World Federation of Combat E-Sports into an even more promising future.",
        part5:
          "Blaise is resolutely determined to advance E-Sports globally, with a particular emphasis on talent development in underrepresented regions such as Cameroon and Africa. His goal is to create equal opportunities for all players, regardless of their geographical origin, and to ensure that E-Sports is recognized as an inclusive and diverse domain. With his visionary leadership, Blaise is committed to working closely with different regions of the world to promote fair competitions, quality infrastructure, and awareness initiatives.",
        part6:
          "As a staunch advocate for training and education, Blaise Diboto will strive to promote learning and development programs for players and E-Sports enthusiasts. He firmly believes that acquiring technical skills and strategic knowledge is essential to enhance players' performance and ensure the long-term growth of the industry. By emphasizing education, he aspires to make E-Sports a viable and respected career choice.",
        part7:
          "His new role as the President of the World Federation of Combat E-Sports also presents a unique opportunity to strengthen cooperation among different regional and national organizations. By fostering collaboration and the exchange of ideas, he aims to create a robust ecosystem where federations work hand in hand to promote best practices, establish fair competition standards, and ensure the well-being of players.",
        part8:
          "In conclusion, this appointment marks a decisive turning point in the industry's history. His inspiring leadership and commitment to inclusion, education, and talent development will be catalysts for sustained growth and increased recognition of E-Sports throughout Cameroon, Africa, and the world. Players in the African continent can be proud to see one of their own in a position of power and responsibility on a global scale. We look forward to witnessing the extraordinary achievements that Blaise Diboto will bring to E-Sports as the President of the World Federation of Combat E-Sports.",
        part9:
          "Notably, the Cameroonian Esport Association has been honored as a special guest at WEC23 this year in Romania, a prestigious event organized by the IESF. This recognition reflects the international acknowledgment of Blaise's commitment to promoting E-Sports. Cameroon will be proudly represented by a member staff and the play-off winner between e-football and Tekken 7 Champions.",
      },

      // Home Page
      mainTitle: {
        part1: "In Kazan, Russia, from October 14 to October 24, 2023",
        part2: "Cameroon takes ",
        part2_2: "third place",
        part3: "cash prize ",
        part4:
          "As part of the Phygital Live project 'Games of the Future,' Cameroon Esports and its certified Gamers with their membership cards embarked on an epic adventure in Russia.",
        part5: " Sign up",
        part6: "for the chance to represent the",
        part7: "Cameroon at the World Cup Events",
      },

      article1: {
        part1:
          "The world of esports is booming, and an exceptional opportunity awaits you through a prestigious partnership between IESF and ITTI. If you've always dreamed of diving into the exciting realm of esports and managing this thriving industry, don't miss out on this golden opportunity that could change your life!",
        part2:
          "IESF (International Esports Federation) and ITTI (Institute of Information Technologies and Telecommunications) are joining forces to offer four (4) full scholarships to ambitious and passionate minds who will begin the program in January 2024. This unique chance is within reach for those wishing to deepen their knowledge in esports management.",
        part3:
          "Let your skills and ideas shine in the booming world of esports! Candidates will have the opportunity to undergo a transparent evaluation process aimed at selecting the best of the best. Imagine the pride of being awarded a full scholarship, a recognition of your exceptional potential in this rapidly growing field.",
        part4:
          "Take control of your future in esports by joining this unique program. Winners will have access to a diverse range of courses taught by experienced speakers and professionals from all corners of the esports industry. It's not just training; it's a total immersion in the fascinating world of esports management.",
        part5: {
          p1: "Application Start Date: August 8th",
          p2: "Application Deadline: August 18th",
          p3: "Announcement of Winners: At the conclusion of the World Esports Championship in Iasi, Romania",
        },
        part6: {
          p1: "We encourage you to seize this extraordinary opportunity to give a new dimension to your passion for esports. Embark on a stimulating learning journey and explore a world of possibilities that could lead you to exceptional success in the booming esports industry.",
          p2: "For more details about this incredible program, visit the following link:",
          p3: "Registrations are open here:",
          p4: "Join us on Facebook and Instagram to stay informed and don't miss this unique opportunity to turn your passion into a rewarding career in esports.",
          p5: "Join us now to shape the future of esports with IESF and ITTI!",
        },
        part7: "Cameroon at the World Cup",
        title1:
          "Secure a Scholarship for the Interactive Esports Course - A Unique Opportunity Offered by IESF and ITTI!",
        title2: "Scholarships for Elite Esports Aspirants",
        title3: "Transparent Evaluation Process for Top Candidates",
        title4: "A New World of Knowledge and Opportunities",
        title5: "Key Dates to Note",
        title6: "Don't Miss This Unique Chance!",
        title7: "Enroll now to shape the future of esports with IESF and ITTI!",
      },

      Découvrir: "Discover",
      "A propos de Cameroon E-Sports": "About Cameroon E-Sports",
      esportsWho: "Cameroon Federation of Electronic Games",

      mainDescription: {
        part1:
          "Cameroon E-Sports is the Cameroonian federation of games and electronic sports (video games). Attached to the International Organization for Electronic and Digital Sports and Games (ESWF), our goal is to bring out the E-Sports champions of Cameroon and ensure the development of E-Sports careers.",
        part2:
          "Cameroon E-Sports was born from a handful of Cameroonian E-Sports enthusiasts who came together around a common passion for E-Sports and a commitment to developing economic opportunities for Cameroonian youth. All while putting the Cameroonian and African E-Sports industry on the world map.",
        part3:
          "Cameroon E-Sports provides an opportunity to empower young Cameroonians economically and socially with the broader goal of transforming lives.",
        part4:
          "We seek to partner with brands, industry leaders and institutions across the world to grow the E-Sports industry in Cameroon and Africa through tournaments, education, technology , development of gaming communities, entertainment, tourism and content distribution.",
      },

      faq: {
        title: "Frequently Asked Questions",
        content: {
          q1: "What is E-sports?",
          r1: "E-Sports is a form of competition that takes place in video games, often online, where players compete individually or in teams to win cash rewards, titles, or ranking points.",

          q2: "Is E-sport considered a sport?",
          r2: "While it is debated, E-Sports is increasingly seen as a sport due to its competitive nature, professional organization and economic impact.",

          q3: "What are the most popular E-Sports games?",
          r3: "The most popular E-Sports games include League of Legends, FIFA23, TEKKEN 7, STREET FIGHTER V, eFootball, Dota 2, Counter-Strike: Global Offensive, Overwatch, Fortnite, Hearthstone, and Rainbow Six Siege.",

          q4: "How can one become a professional E-Sports player?",
          r4: "To become a professional player in E-Sports, you must acquire expertise in one or more games, train regularly, participate in competitions and be noticed by professional teams.",

          q5: "Are there E-Sports competitions in Cameroon and Africa?",
          r5: "Yes, there are many E-Sports competitions in Cameroon and Africa.",

          q6: "Can women participate in E-sports?",
          r6: "Absolutely, women are also present in E-Sports and can compete with men at the highest level of competition.",

          q7: "How can I watch E-Sports competitions?",
          r7: "Esports competitions are often broadcast live on online platforms such as Facebook or YouTube, as well as on dedicated esports television channels.",
        },
      },

      joinEsports: {
        part1: "Join Cameroon E-Sports and shape",
        part2: "your future as a professional",
        part3:
          "Like a Cameroonian sports association approved by the Ministry of Sports and Physical Education, we register and officially issue membership cards to Cameroonian Gamers, we organize national championships, participate in African competitions and also in the World Cup of Games and electronic sports (video games), the next one being held in Cameroon.",
        part4:
          "Furthermore, we connect and protect Gamers, streamers and supporters through the METAGAMES movement organized by the international federation of sports and electronic games. ",
      },

      // Login and Registration form
      "FROM DOUALA": "OF DOUALA",
      Registration: "Registration",
      "Last name": "Last name",
      "First name": "First name",
      Email: "Email",
      "Phone number": "Phone number",
      City: "City",
      "Select a city": "Select a city",
      Status: "Status",
      Address: "Address",
      Password: "Password",
      Register: "Register",
      Donation: "Make a donation",
      Amount: "Amount",
      "make my donation": "make my donation",
      "Already have an account": "Already have an account",
      "No account": "No account",
      Dear: "Dear",
      confirmOTP: {
        part1:
          "A confirmation link has been messaged to the number you provided when registering. This message contains a validation link for your account. To activate your account, please click on this link and follow the instructions.",
        part2:
          "If you did not receive this email, please check your spam folder or contact our support team for assistance.",
        part3:
          "We look forward to seeing you on our platform and sharing the passion for E-Sports with you.",
        part4: "Regards, The E-Sports Gaming Cameroon team",
        part5:
          "A confirmation code has been messaged to the number you provided when you registered.",
        part6: "Please enter this code to continue.",
      },
      Validate: "Validate",
      Close: "Close",
      becomePro: {
        title1: "How to become a Gamer-Pro",
        part1:
          "It's simple. Register on the Cameroon E-Sports platform and get a gaming membership card.",
        title2: "Get your gaming membership card",
        part2:
          "This gaming membership card not only allows you to be recognized as Professional Gamers by all gaming institutions, but also gives you the right to participate in all national and international gaming competitions.",
        title3: "Compete in different championships",
        part3:
          "As insinuated in the previous paragraph, with your membership card, you have access to all the national and international competitions that will be held (including the 2023 gaming world cup and the playoffs)",
        title4: "Win different championships",
        part4:
          "Obviously, if you participate in a championship, it's to win it! Big cash and in-kind gains are waiting for you. Your card is valid for 1 year renewable each year.",
      },
      lastEditions: {
        part1: "Old editions",
        part2: "in picture",
        part3: "See more",
      },
      advantages: {
        title: "Benefit of a gaming membership card",

        t1: "To be ranked as a World Athlete in MetaSports: ",
        a1: "Thus, you will be recognized by the International Federation of Electronic Games and Sports and all other gaming institutions across Africa and the world.",

        t2: "Get direct opportunities for development and careers",
        a2: "you will now be able to earn a living as a professional gamer",

        t3: "Being involved in safe, secure and fair competitions",
        a3: "you will have the opportunity to compete against the world's greatest gamers and make your way up the food chain",

        t4: "Being part of a network focused on the future of esports",
        a4: "you will have all the support so that you can improve yourself.",
      },
      Location: "Location",
      Cameroon: "Cameroon",
      "Powered by": "Powered by",
      Federation: "Cameroonian Electronic Sports Federation",
      joinGaming: {
        title: "Join the global gaming competition",
        date: "October 2024 to Febuary 2025",
        part1:
          "The next session will take place in Cameroon. And our goal is to involve the best in the country and ensure high visibility for this event, which will be held for the first time in Cameroon. A golden opportunity for you to show the world what you are capable of.",

        part2: "Competition throughout the national territory",
        part3: "Participants selected across the country",
        part4: "Games in competition",
        part5: "bonus for winners",
      },

      cardPage: {
        title1: "Your gaming membership card",
        title2: "Cameroon E-Sports World Cup 2023 over",
        title3: "Valid until",
        title4: "Pay your membership card now",
        title6: "",
        title7: "",
      },
      Logout: "Logout",
      Pay: "Buy",
      "Payment Method": "Payment Method",
      PayCard: "Payment of your Gaming card",
      PayBy: "Payment by",
      ConfirmPayment: "Please confirm the payment on your mobile phone.",
      OnPayment: "payment in progress",

      Cards: "Cards",
      Member: "Member",
      History: "History",
      Profile: "Profile",
      Save: "Save",
      Price: "Price",
      "Service fee": "Service fee",
      "transaction has been cancelled": "transaction has been cancelled",
      "transaction has been rejected":
        "transaction has been rejected,please try again after a few minutes.",
      "Please validate the payment on your mobile please":
        "Please validate the payment on your mobile please",
      "An error occurred during payment, please check your information, your balance and try again.":
        "An error occurred during payment, please check your information, your balance and try again.",

      JustDance:
        "Just Dance is a dance video game series developed and published by Ubisoft. In the game, players follow dance moves on the screen, using a motion sensor or their smartphone to detect their movements.",
      EFootball:
        "E-Football is a football simulation video game developed by Konami Digital Entertainment. It is an updated and improved version of the Pro Evolution Soccer (PES) football video game franchise",
      Tekken7:
        "Tekken 7 is a 3D fighting game featuring fighters from around the world, each with their own unique fighting style and story. The game features single and multiplayer game modes, spectacular graphics and gameplay fluid.",
      Street5:
        "Street Fighter 5 is a 3D fighting game that features iconic characters like Ryu, Ken, and Chun-Li, as well as new fighters with unique fighting styles. The game offers enhanced graphics and smooth animations, as well as single and multiplayer game modes.",
      WorldCup: "World Cup",
      UniversityGames: "University Games",
      SendDemand: "Send your request to participate in the 2023 competitions",
      needToPayBefore: "You must first pay for a gamer card to continue.",
      payNow: "Pay Now",
      "I participate": "I participate",
      sending: "sending...",
      send: "Send",
      resendOTPCode: "resend code",
      "A new OTP code has been sent to you.":
        "A new OTP code has been sent to you.",
      "Failed to send OTP code": "Failed to send OTP code",

      Login: "Login",
      "Join the team": "Join the team",
      "Telephone or e-mail address": "Phone number or e-mail address",
      "Forgot your password ?": "Forgot your password ?",
      "Remember me": "Remember me",
      "Reset your password": "Reset your password",
      reset: "Reset",

      "mobile money payment": "Mobile money payment",
      "payment by credit card": "Payment by credit card",
      "Select mobile operator": "Select mobile operator",
      "Payment by": "Payment by",
      Filter: "Filtered",
      Search: "Search",
      NickName: "Nickname",
      competition: "competition",
      certified: "certified",
      nothing: "nothing",
      "Transaction history": "Transaction history",
      "Bought on": "Bought on",
      rendered: "rendered",
      "payment method": "payment method",
      "Your profile has been updated": "Your profile has been updated.",
      WorldCupCameroon: "World Cup Cameroon 2023",
      WorldCupRomania: "World Cup Romania 2023",

      // validation rules
      "incorrect last name": "incorrect last name",
      "incorrect first name": "incorrect first name",
      "incorrect email": "incorrect email",
      "incorrect phone": "incorrect phone number",
      "incorrect city": "incorrect city",
      "incorrect address": "incorrect address",
      "incorrect password": "password length must be greater than six",

      "Login successfully redirection...": "Login successfully redirection...",

      "Your password has been successfully changed ! we will redirect you to the login page...":
        "Your password has been successfully changed ! we will redirect you to the login page...",
      "The reset link has been sent to your email/phone please verify it.":
        "The reset link has been sent to your email/phone please verify it.",

      PressRelease1: {
        title: "Press Release Dakar, October 04, 2023",
        part1: {
          p1: "The Confederation of Electronic Sports Africa (CESA) was founded on Tuesday September 26,2023.",
          p2: "Following an invitation sent by the Ministry of Sports to 45 delegates from African  countries mandated by their respective states, the Constitutive General Assembly was held on",
          p3: "September 25 and 26, 2023 in Dakar, ending with a unanimous vote by the countries to create",
          p4: "September 25 and 26, 2023 in Dakar, ending with a unanimous vote by the countries to create",
          p5: "this continental organization for the promotion, training and development of Electronic Games",
          p6: "& Immersive Sports and their related activities.",
        },
        part2: {
          p1: "An Organizing Committee made up of the following countries was at the heart of the organization:",
          list1: {
            l1: "Nigeria",
            l2: "Ghana",
            l3: "Mauritius",
            l4: "Zimbabwe",
            l5: "Ivory Coast",
            l6: "Senegal",
          },
        },
        part3: {
          p1: "The mission of the Confédération Africaine des Sports Electroniques (CASE) is, among other things, to coordinate, develop and initiate any action to represent, educate, train, disseminate and promote the practice of Esport throughout Africa. To choose the president, the countries voted unanimously to appoint Mr. Samba Bathily CEO of the Africa Development Solutions (ADS) Group as head of this continental organization. ",
          p2: "CASE has divided the continent's countries into six zones: ",
          list1: {
            l1: "North zone",
            l2: "West Zone A",
            l3: "West Zone B",
            l4: "Central zone",
            l5: "Central East Zone",
            l6: "South Zone",
          },
          p3: "Out of 13 members of the Executive Committee, only 9 countries have been appointed:",
          list2: {
            l1: "Cameroon",
            l2: "Congo",
            l3: "Ghana",
            l4: "Ivory Coast",
            l5: "Cabo Verde",
            l6: "Mali",
            l7: "Mauritius",
            l8: "Madagascar",
            l9: "Somalia",
          },
          p4: "In his closing speech, Mr. Samba Bathily, the newly appointed Chairman of CESA, pledged to develop Gaming and Esport activities across the continent. Through his ADS Group, he announced that 50% of the cost of setting up 'HP Gaming Garage' training centers would initially be financed in one country in each zone of the The Confederation of Electronic Sports Africa (CESA)",
          p5: "Numerous personalities attended the closing ceremony, including: ",
          list3: {
            l1: "Mr. Souleymane Astou Diagne, Director of the Cabinet of the Minister of Communication, Telecommunications and the Digital Economy",
            l2: "Mr. Ousseynou Dieng, Director of Communication at the Ministry of Communication, Telecommunications and the Digital Economy, who closed the General Meeting.",
            l3: "Mr. Bakhoum, Managing Director of Sénégal Numérique SA, the first partner of the Fédération Sénégalaise Des Jeux Électroniques, currently CONAPES, which has made available to the federal organization the 45 Sénégal Services spaces dedicated to Esport training and competitions.",
            l4: "The presence of the Orange Middle East Africa Group, represented by Mr. Issa Sene, Sponsorship Director for the zone, who was present throughout the proceedings. ",
            l5: "The Managing Director of Smart Africa, Mr. Lancina Koné and Mr. Amadou Gallo Fall President of the Basketball Africa League (BAL) and Senior Vice President of the NBA, who delivered messages of support to the participants. ",
            l6: "Mathieu Faye Vice-President of FIBA Africa and President of the West Africa Zone",
            l7: "Mayank Dhingra Senior Education Business Leader Europe, Africa and Middle East at HP",
          },
          p6: "The Constitutive General Assembly for the establishment of a Pan-African Confederation around Esport and its related activities and professions was organized under the aegis of His Excellency, Mr. Macky SALL, President of the Republic of Senegal, the Ministry of Communication, des Télécommunications et de l'Économie Numérique de la République du Sénégal and the sponsorship of the ADS Group, and by « Fédération Sénégalaise des Jeux Électroniques et Sports Immersifs », currently « Comité National de Promotion du E-Sport » (CONAPES), under the aegis of the Ministry of Sports, and several other similar legally constituted African structures (Federations, Committees, etc.). ",
          p7: "Institutions such as Unesco, HP Education Middle East and Africa, VISA, FIBA Africa, the Sport Council of the African Union, the Senegalese Basketball Federation, the Senegalese ",
          p8: "Olympic Committee, as well as major global esport institutions, including several personalities from the world of video games, took part in the event. ",
          p9: "For further information, please contact: Mr Mamadou Kane on +221 77 894 13 58 / Mr Boubacar Ba on +221 77 485 72 64 / Mr Koffi LATZOO on +221 70 946 24 89",
        },
      },
      Communique: {
        title: "Press release",
        part1: {
          p1: "Cameroon E-Sports has just signed an agreement with EreaDrone, a French Company Partner of the International Aeronautical Federation.",
          p2: "Drone enthusiasts and professionals now have their Esport competitions through the e-Drone downloadable on Stream.",
          p3: " For any information, please contact the Technical Directorate of Cameroon E-Sports.",
          p4: "Blaise DIBOTO",
          p5: "Cameroon E-Sports President",
        },
      },
    },
  },
  fr: {
    translation: {
      // Navbar
      Accueil: "Accueil",
      About: "A proppos",
      Actuality: "Actualité",
      Auth: "S'inscrire/Se connecter",
      "Liste des inscrits": "Liste des inscrits",
      "Enrégistres-toi": "Enregistres-toi",

      // About Page
      aboutDescription: {
        title:
          "BLAISE DIBOTO, VISIONNAIRE ET PASSIONNÉ, ÉLU PRÉSIDENT DE LA FÉDÉRATION MONDIALE DES JEUX DE COMBAT E-SPORTS",
        part1:
          "Dans une annonce qui a fait sensation dans le monde de l'E-Sport, Blaise Diboto, le dynamique Président de l’Association Camerounaise des jeux Esport, a été nommé Président de la prestigieuse Fédération mondiale des jeux de combat E-Sport. Cette nomination marque une victoire monumentale pour la communauté des joueurs camerounais, africains et pour la Fédération, qui s'enrichira des compétences et des connaissances approfondies de Blaise en Relations publiques et Communication Evenementielle.",
        part2:
          "L'enthousiasme est palpable au sein de la Fédération alors que nous attendons avec impatience les réalisations que Blaise accomplira dans ses nouvelles fonctions. Son côté poussée d’humilité et de partage toujours au service du travail bien fait pour le bien de tous et son dévouement envers l'avancement de l’E-Sports sont indéniables. Grâce à son expertise inégalée, Blaise est bien placé pour propulser le jeu de combat E-Sport vers de nouveaux sommets.",
        part3:
          "Sa nomination à la tête de la Fédération mondiale des jeux de combat E-Sport est une preuve claire de l'essor que connaît cette discipline à travers le monde. L'E-Sport a émergé comme une force incontournable dans le paysage du divertissement, attirant des millions de spectateurs et offrant des opportunités lucratives aux talents émergents.",
        part4:
          "En tant que Président de Cameroun Esport, Blaise Diboto a prouvé sa capacité à rassembler et à inspirer une communauté de joueurs passionnés. Sous sa direction, l’Association Camerounaise des jeux Esport a connu une expansion sans précédent, offrant une plateforme plus précisement une application numérique (https://cameroon-esports.com/ que vous pourrez visiter) de classe mondiale, et des opportunités de compétition stimulantes pour les jeunes talents. La combinaison de ses compétences en leadership et de sa compréhension approfondie en Relations Publiques et Communication événementielle font de lui le choix idéal pour diriger la Fédération mondiale des jeux de combat E-Sport vers un avenir encore plus prometteur.",
        part5:
          "Ce dernier est résolument déterminé à faire progresser l'E-Sport à travers le monde, en mettant un accent particulier sur le développement des talents dans des régions sous-représentées comme le Cameroun et l'Afrique. Son objectif est de créer des opportunités égales pour tous les joueurs, indépendamment de leur origine géographique, et de veiller à ce que l'E-Sport soit reconnu comme un domaine inclusif et diversifié. Grâce à son leadership visionnaire, Blaise s'engage à travailler en étroite collaboration avec les différentes régions du monde pour promouvoir des compétitions équitables, des infrastructures de qualité et des initiatives de sensibilisation.",
        part6:
          "En tant que fervent défenseur de la formation et de l'éducation, Blaise Diboto s'emploiera à promouvoir des programmes d'apprentissage et de développement pour les joueurs et les passionnés de jeux de combat E-Sport. Il croit fermement que l'acquisition de compétences techniques et de connaissances stratégiques est essentielle pour améliorer les performances des joueurs et garantir la croissance à long terme de l'industrie. En mettant l'accent sur l'éducation, il aspire à faire de l'E-Sport un choix de carrière viable et respecté.",
        part7:
          "Son nouveau rôle en tant que Président de la Fédération mondiale des jeux de combat E-Sport présente également une opportunité unique pour renforcer la coopération entre les différentes organisations régionales et nationales. En favorisant la collaboration et l'échange d'idées, il vise à créer un écosystème solide où les fédérations travaillent main dans la main pour promouvoir les meilleures pratiques, établir des normes de compétition équitables et garantir le respect des joueurs.Pour finir, cette nomination marque un tournant décisif dans l'histoire de l'industrie. Son leadership inspirant et son engagement envers l'inclusion, l'éducation et le développement des talents seront des catalyseurs pour une croissance soutenue et une reconnaissance accrue de l'E-Sport à travers le Cameroun, l’Afrique et le monde. Les joueurs du continent africain peuvent être fiers de voir l'un des leurs occuper une position de pouvoir et de responsabilité à l'échelle mondiale. Nous sommes impatients de voir les réalisations extraordinaires que Blaise Diboto apportera à l'E-Sport en tant que Président de la Fédération mondiale des jeux de combat E-Sport.",
        part9:
          "Sans oublier que l’Association Camerounaise des jeux E-SPORT s'est vue décerner l'honneur d'être un invitée spéciale du WEC23 cette année en Roumanie, un événement prestigieux organisé par l'IESF. Cette distinction témoigne de la reconnaissance internationale de l'engagement de Blaise envers la promotion des jeux E-Sport. Le Cameroun se verra fièrement représenté par un membre du staff et un Gamer qui sortira victorieux des Play-offs entre les champions d'eFootball et de Tekken 7.",
      },

      // Home Page
      mainTitle: {
        part1: "KAZAN, RUSSIE Du 14 au 24 Octobre 2023",
        part2: "Le Cameroun se hisse à la ",
        part2_2: "troisième place",
        part3: "en prime",
        part4:
          "Dans le cadre du projet Phygital Live « Jeux du futur », Cameroon Esports et ses Gamers certifiés par leurs cartes membres se sont rendus en Russie pour une aventure épique.",
        part5: " Inscris-toi",
        part6: "pour avoir la chance de représenter le",
        part7: "Cameroun aux compétitions internationales.",
      },
      article1: {
        part1: `Le monde des sports électroniques est en pleine effervescence, et une opportunité exceptionnelle se
        présente à vous grâce à un partenariat de prestige entre l'IESF et l'ITTI. Si vous avez toujours rêvé de
        plonger dans l'univers passionnant des sports électroniques et de la gestion de ce domaine en plein
        essor, ne manquez pas cette occasion en or qui pourrait changer votre vie !`,
        part2: `L'IESF (Fédération Internationale des Sports Électroniques) et l'ITTI (Institut des Technologies et des
          Télécommunications de l'Information) s'associent pour offrir quatre (4) bourses complètes aux
          esprits ambitieux et passionnés qui entameront le programme en janvier 2024. Cette chance unique
          est à portée de main pour tous ceux qui souhaitent approfondir leurs connaissances en gestion des
          sports électroniques.
          `,
        part3: `Laissez briller vos compétences et vos idées dans le domaine en plein essor des sports électroniques !
        Les candidats auront l'opportunité de se soumettre à un processus d'évaluation transparent, visant à
        sélectionner les meilleurs parmi les meilleurs. Imaginez la fierté de se voir attribuer une bourse
        complète, une reconnaissance de votre potentiel exceptionnel dans ce domaine en pleine croissance.`,
        part4: `Prenez le contrôle de votre avenir dans les sports électroniques en rejoignant ce programme unique.
        Les lauréats auront accès à une gamme variée de cours, dispensés par des conférenciers et des
        professionnels chevronnés issus de tous les coins de l'industrie des sports électroniques. Ce n'est pas
        seulement une formation, c'est une immersion totale dans le monde fascinant de la gestion des
        sports électroniques.`,
        part5: {
          p1: `Début des candidatures : 8 août`,
          p2: `Date limite de candidature : 18 août`,
          p3: `Annonce des candidats gagnants : À la clôture du Championnat Mondial des Sports
          Électroniques à Iasi, Roumanie`,
        },
        part6: {
          p1: `Nous vous encourageons à saisir cette opportunité extraordinaire pour donner une nouvelle
          dimension à votre passion pour les sports électroniques. Engagez-vous dans un voyage
          d'apprentissage stimulant et explorez un monde de possibilités qui pourraient vous conduire vers un
          succès exceptionnel dans l'industrie en plein essor des sports électroniques.
          Pour plus de détails sur ce programme incroyable, rendez-vous sur le lien suivant :`,
          p2: `https://itti.es/esports-management/`,
          p3: `Les inscriptions se font par ici: `,
          p4: `https://ie-sf.us4.listmanage.com/track/click?u=470a346e0b76b7c2be931fbf3&id=b1ae5c5480&e=2335985e61`,
          p5: `Rejoignez-nous sur Facebook et Instagram pour rester informé et ne manquez pas cette opportunité
       unique de transformer votre passion en une carrière épanouissante dans les sports électroniques.`,
        },

        part7: "Cameroon at the World Cup",

        title1:
          "Décrochez une Bourse pour le Cours Interactif sur les Sports Électroniques - Une Opportunité Unique Offerte par IESF et ITTI !",
        title2: "Bourses pour l'Élite des Aspirants en Sports Électroniques",
        title3:
          "Un Processus d'Évaluation Transparent pour les Meilleurs Candidats",
        title4: "Un Nouveau Monde de Connaissances et d'Opportunités",
        title5: "Dates Clés à Noter",
        title6: "Ne Manquez Pas cette Chance Unique !",
        title7: `Inscrivez- vous dès maintenant pour façonner l'avenir des sports électroniques avec IESF et ITTI !`,
      },
      Découvrir: "Découvrir",
      "A propos de Cameroon E-Sports": "A propos de Cameroon E-Sports",
      esportsWho: "Fédération Camerounaise des jeux électroniques",

      mainDescription: {
        part1:
          "Cameroon E-Sports est la fédération camerounaise des jeux et sports électroniques (jeux vidéo). Rattachée à l’Organisation internationale des sports et jeux électroniques et digitaux (ESWF), notre but est de faire émerger les champions E-Sports du Cameroun et d’assurer le développement des carrières eSports.",
        part2:
          "Cameroon E-Sports est né d’une poignée de passionnés d’E-Sports camerounais qui se sont réunis autour d’une passion commune pour l’E-Sports et d’un engagement à développer les opportunités économiques pour la jeunesse camerounaise. Le tout en plaçant l’industrie camerounaise et africaine de l’E-Sports sur la carte mondiale.",
        part3:
          "Cameroon E-Sports offre une opportunité permettant l’indépendance des jeunes camerounais économiquement et socialement avec l’objectif plus large de transformer des vies.",
        part4:
          "Nous cherchons à nous associer à des marques, des leaders de l’industrie et des institutions à travers le monde pour développer l’industrie de l’E-Sports au Cameroun et en Afrique à travers des tournois, l’éducation, la technologie, le développement des communautés de gamers, le divertissement, le tourisme et la distribution des contenus.",
      },

      faq: {
        title: "Questions fréquemment posées",
        content: {
          q1: "Qu'est-ce que l'E-Sports ?",
          r1: "L'esport est une forme de compétition qui se déroule sur des jeux vidéo, souvent en ligne, où des joueurs s'affrontent individuellement ou en équipe pour gagner des récompenses en argent, des titres ou des points de classement.",

          q2: "L'esport est-il considéré comme un sport ?",
          r2: "Bien que cela fasse l'objet de débats, l'esport est de plus en plus considéré comme un sport en raison de son caractère compétitif, de son organisation professionnelle et de son impact économique.",

          q3: "Quels sont les jeux les plus populaires en esport ?",
          r3: "Les jeux les plus populaires en esport incluent League of Legends,FIFA23, TEKKEN 7, STREET FIGHTER V, eFootball, Dota 2, Counter-Strike: Global Offensive, Overwatch, Fortnite, Hearthstone et Rainbow Six Siege.",

          q4: "Comment peut-on devenir un joueur professionnel en esport ?",
          r4: "Pour devenir joueur professionnel en esport, il faut acquérir une expertise dans un ou plusieurs jeux, s'entraîner régulièrement, participer à des compétitions et se faire remarquer par les équipes professionnelles.",

          q5: "Y a-t-il des compétitions d'esport au Cameroun et en Afrique ?",
          r5: "Oui, il existe de nombreuses compétitions d'esport au Cameroun et en Afrique.",

          q6: "Les femmes peuvent-elles participer à l'esport ?",
          r6: "Absolument, les femmes sont également présentes dans l'esport et peuvent rivaliser avec les hommes au plus haut niveau de compétition.",

          q7: "Comment puis-je regarder des compétitions d'esport ?",
          r7: "Les compétitions d'esport sont souvent diffusées en direct sur des plateformes en ligne telles que Facebook ou YouTube, ainsi que sur des chaînes de télévision dédiées à l'esport.",
        },
      },

      joinEsports: {
        part1: "Rejoins Cameroon E-Sports et façonne",
        part2: "ton avenir en tant que professionnel",
        part3:
          "Telle une association sportive camerounaise agrée par le ministère des sports et de l' éducation physique, nous enregistrons et délivrons officiellement des cartes membres aux Gamers camerounais, nous organisons des championnats nationaux, participons aux compétitions africaines et également à la coupe du monde des jeux et sports électroniques (jeux vidéo), dont la prochaine se tient au Cameroun.",
        part4:
          "De plus, nous connectons et protégeons les Gamers, les streamers et les supporters à travers le mouvement METAGAMES organisé par la fédération internationale des sports et jeux électroniques. ",
      },

      // Login and Registration form
      "FROM DOUALA": "DE DOUALA",
      Registration: "Inscription",
      "Last name": "Nom",
      "First name": "Prénom",
      Email: "Email",
      "Phone number": "Numéro de téléphone",
      City: "Ville",
      Address: "Adresse",
      Password: "Mot de passe",
      Register: "S'inscrire",
      "make my donation": "faire mon don",
      Donation: "Faire un don",
      Amount: "Montant",
      "Already have an account": "Déjà un compte",
      "No account": "Pas de compte",
      Dear: "Cher",
      confirmOTP: {
        part1:
          "Un lien de confirmation a été envoyé par message au numéro que vous avez fourni lors de votre inscription. Ce message contient un lien de validation de votre compte. Pour activer votre compte, veuillez cliquer sur ce lien et suivre les instructions.",
        part2:
          "Si vous n'avez pas reçu cet e-mail, veuillez vérifier votre dossier de courrier indésirable (spam) ou contactez notre équipe d'assistance pour obtenir de l'aide.",
        part3:
          "Nous sommes impatients de vous voir sur notre plateforme et de partager avec vous la passion pour l'esport.",
        part4: "Cordialement, L'équipe E-Sports Gaming Cameroon",
        part5:
          "Un code de confirmation a été envoyé par message au numéro que vous avez fourni lors de votre inscription.",
        part6: "Veuillez entrer ce code pour continuer svp.",
      },
      Validate: "Valider",
      Close: "Fermer",
      becomePro: {
        title1: "Comment devenir un Gamer-Pro",
        part1:
          "C’est simple. Inscris-toi sur la plateforme de Cameroon E-Sports et procure-toi une carte membre de gaming.",
        title2: "Obtiens ta carte membre de gaming",
        part2:
          "Cette carte membre de gaming te permet non seulement d’être reconnu comme Gamers Professionnels par toutes les institutions de gaming, et te donne également le droit de participer à toutes les compétitions nationales et internationales de gaming.",
        title3: "Participe à  différents championnats",
        part3:
          "Comme insinuer dans le paragraphe précédent, avec ta carte membre, tu as accès à toutes les compétitions nationales et internationales qui se tiendront (notamment la coupe du monde de gaming 2023 et aux éliminatoires)",
        title4: "Remporte  différents championnats",
        part4:
          "Evidemment, si tu participes à un championnat, c’est pour le remporter ! Du gros cash et des gains en nature n’attendent plus que toi.\nTa carte est valable 1an renouvelable chaque année.",
      },
      lastEditions: {
        part1: "Les anciennes éditions",
        part2: "en image",
        part3: "Voir plus",
      },
      advantages: {
        title: "Avantage d’une carte membre  gaming",

        t1: "Être classé(e) officiellement comme athlète mondial(e) dans MetaSports : ",
        a1: "ainsi, tu seras reconnu par la fédération internationale des jeux et sports électroniques et toutes les autres institutions de gaming à travers l’Afrique et le monde.",

        t2: "Obtenir des opportunités directes pour le développement et les carrières ",
        a2: "tu pourras désormais gagner ta vie en tant que Gamer professionnel",

        t3: "Être impliqué(e) dans des compétitions sûres, sécurisées et équitables ",
        a3: "tu auras la possibilité d’affronter les plus grands gamers mondiaux et te faire une place dans la chaîne alimentaire",

        t4: "Faire partie d'un réseau qui se concentre sur l'avenir de l'e-sport",
        a4: "tu auras tout un accompagnement pour que tu puisses t’améliorer.",
      },
      Localization: "Localisation",
      Cameroon: "Cameroun",
      "Powered by": "Propulsé par",
      Federation: "Fédération Camerounaises des sports électroniques",
      joinGaming: {
        title: "Rejoins la compétition mondiale de gaming",
        date: "Octobre 2024 à Février 2025",
        part1:
          "La prochaine séance se déroulera au Cameroun. Et notre objectif, c’est d’y faire participer les meilleurs du pays et d’assurer une grande visibilité à cet événement qui se tiendra pour la première fois au Cameroun. Une occasion en or pour toi de montrer aux yeux du monde de quoi tu es capable.",

        part2: "Compétition sur tout le territoire nationale",
        part3: "Participants sélectionnés sur l’ensemble du territoire",
        part4: "Jeux en compétition",
        part5: "de prime pour les vainqueurs",
      },
      cardPage: {
        title1: "Votre carte membre gaming",
        title2: "Coupe du monde Cameroun E-Sports 2023 plus de",
        title3: "Valide jusqu'au",
        title4: "Payer votre carte membre maintenant",
        title6: "",
        title7: "",
      },
      Logout: "Déconnexion",
      Pay: "Acheter",
      "Payment Method": "Methode de paiement",
      PayCard: "Payement de votre carte Gaming",
      PayBy: "Payement par",
      ConfirmPayment:
        "Veuillez confirmer le payement sur votre téléphone mobile SVP.",
      OnPayment: "payement en cours",

      Cards: "Cartes",
      Profile: "Profil",
      Member: "Membre",
      History: "Historique",
      Save: "Enregistrer",
      Price: "Prix",
      "Service fee": "Frais de service",

      "transaction has been cancelled": "La transaction a été annulée",
      "transaction has been rejected":
        "transaction a été rejetée, veuillez réessayer après quelques minutes.",
      "Please validate the payment on your mobile please":
        "Merci de valider le paiement sur votre mobile svp",
      "An error occurred during payment, please check your information, your balance and try again.":
        "Une erreur s'est produite lors du paiement, veuillez vérifier vos informations, votre solde et réessayer.",
      "An error occured": "Une erreur s'est produite, veuillez réessayer.",
      "Your participation has been saved":
        "Votre participation a bien été enregistré",

      JustDance:
        "Just Dance est une série de jeux vidéo de danse développée et éditée par Ubisoft. Dans le jeu, les joueurs suivent les mouvements de danse sur l'écran, en utilisant un capteur de mouvement ou leur smartphone pour détecter leurs mouvements.",
      EFootball:
        "E-Football est un jeu vidéo de simulation de football développé par Konami Digital Entertainment. Il s'agit d'une version mise à jour et améliorée de la franchise de jeux vidéo de football Pro Evolution Soccer (PES)",
      Tekken7:
        "Tekken 7 est un jeu de combat en 3D qui met en scène des combattants du monde entier, chacun avec son propre style de combat et son histoire. Le jeu comprend des modes de jeu solo et multijoueur, des graphismes spectaculaires et une jouabilité fluide.",
      Street5:
        "Street Fighter 5 est un jeu de combat en 3D qui propose des personnages emblématiques tels que Ryu, Ken et Chun-Li, ainsi que de nouveaux combattants avec des styles de combat uniques. Le jeu offre des graphismes améliorés et des animations fluides, ainsi que des modes de jeu solo et multijoueur.",
      WorldCup: "Coupe du monde",
      UniversityGames: "Jeux universitaires",
      SendDemand:
        "Envoyer votre demande de participation aux compétitions de 2023",
      needToPayBefore:
        "Vous devez au préalable payer une carte gamer pour continuer.",
      payNow: "Payer Maintenant",
      "I participate": "Je participe",
      sending: "envoie...",
      send: "Envoyer",
      resendOTPCode: "renvoyer le code",
      "A new OTP code has been sent to you.":
        "Un nouveau code OTP vous a été envoyé.",

      Login: "Connectez vous",
      "Join the team": "Rejoindre l'équipe",
      "Telephone or e-mail address": "Téléphone ou adresse e-mail",
      "Forgot your password ?": "Mot de passe oublié ?",
      "Remember me": "Se souvenir de moi",
      "Reset your password": "Réinitialiser votre mot de passe",
      reset: "Réinitialiser",

      "mobile money payment": "Payement par mobile money",
      "payment by credit card": "Payement par carte de crédit",
      "Select mobile operator": "Sélectionner l'opérateur mobile",
      "Payment by": "Paiment par",
      Filter: "Filtre",
      Search: "Rechercher",
      NickName: "Surnom",
      competition: "compétition",
      certified: "certifié",
      nothing: "aucune",
      "Transaction history": "Historique des transactions",
      "Bought on": "Acheté le",
      rendered: "rendu",
      "payment method": "méthode de paiment",
      "Your profile has been updated": "Votre profil a été mis à jour.",
      WorldCupCameroon: "Coupe du monde Cameroun 2023",
      WorldCupRomania: "Coupe du monde Roumanie 2023",

      // validation rules
      "incorrect last name": "nom incorrect",
      "incorrect first name": "prénom incorrect",
      "incorrect email": "adresse mail incorrect",
      "incorrect phone": "numéro de téléphone incorrect",
      "incorrect city": "ville incorrect",
      "incorrect address": "addresse incorrect",
      "incorrect password": "mot de passe trop court",

      "Login successfully redirection...": "Connexion réussie redirection...",

      "Your password has been successfully changed ! we will redirect you to the login page...":
        "Votre mot de passe a été changé avec succès ! nous vous redirigerons vers la page de connexion...",
      "The reset link has been sent to your email/phone please verify it.":
        "Le lien de réinitialisation a été envoyé à votre adresse e-mail/téléphone, veuillez le vérifier.",
      PressRelease1: {
        title: "Communiqué de presse Dakar, le 04 octobre 2023",
        part1: {
          p1: "La Confédération des Sports Électroniques d'Afrique (CESA) a été fondée le mardi 26 septembre 2023.",
          p2: "Suite à une invitation envoyée par le Ministère des Sports à 45 délégués en provenance de pays africains mandatés par leurs États respectifs, l'Assemblée Générale Constitutive s'est tenue les",
          p3: "25 et 26 septembre 2023 à Dakar, se concluant par un vote unanime des pays pour créer",
          p4: "25 et 26 septembre 2023 à Dakar, se concluant par un vote unanime des pays pour créer",
          p5: "cette organisation continentale pour la promotion, la formation et le développement des Jeux Électroniques",
          p6: "et des Sports Immersifs, ainsi que de leurs activités connexes.",
        },
        part2: {
          p1: "Un Comité d'Organisation composé des pays suivants était au cœur de l'organisation :",
          list1: {
            l1: "Nigeria",
            l2: "Ghana",
            l3: "Maurice",
            l4: "Zimbabwe",
            l5: "Côte d'Ivoire",
            l6: "Sénégal",
          },
        },
        part3: {
          p1: "La mission de la Confédération Africaine des Sports Électroniques (CASE) est, entre autres, de coordonner, développer et initier toute action visant à représenter, éduquer, former, diffuser et promouvoir la pratique de l'Esport à travers toute l'Afrique. Pour choisir le président, les pays ont voté à l'unanimité pour nommer M. Samba Bathily, PDG du Groupe Africa Development Solutions (ADS), à la tête de cette organisation continentale.",
          p2: "CASE a divisé les pays du continent en six zones :",
          list1: {
            l1: "Zone Nord",
            l2: "Zone Ouest A",
            l3: "Zone Ouest B",
            l4: "Zone Centrale",
            l5: "Zone Centre-Est",
            l6: "Zone Sud",
          },
          p3: "Sur les 13 membres du Comité Exécutif, seuls 9 pays ont été nommés :",
          list2: {
            l1: "Cameroun",
            l2: "Congo",
            l3: "Ghana",
            l4: "Côte d'Ivoire",
            l5: "Cap-Vert",
            l6: "Mali",
            l7: "Maurice",
            l8: "Madagascar",
            l9: "Somalie",
          },
          p4: "Dans son discours de clôture, M. Samba Bathily, le nouveau Président de la CESA, s'est engagé à développer les activités de Jeux et d'Esport à travers le continent. Par le biais de son groupe ADS, il a annoncé que 50 % du coût de mise en place des centres de formation 'HP Gaming Garage' serait initialement financé dans un pays de chaque zone de la Confédération des Sports Électroniques d'Afrique (CESA).",
          p5: "De nombreuses personnalités ont assisté à la cérémonie de clôture, notamment :",
          list3: {
            l1: "M. Souleymane Astou Diagne, Directeur de Cabinet du Ministre de la Communication, des Télécommunications et de l'Économie Numérique",
            l2: "M. Ousseynou Dieng, Directeur de la Communication au Ministère de la Communication, des Télécommunications et de l'Économie Numérique, qui a clôturé l'Assemblée Générale.",
            l3: "M. Bakhoum, Directeur Général de Sénégal Numérique SA, premier partenaire de la Fédération Sénégalaise Des Jeux Électroniques, actuellement CONAPES, qui a mis à la disposition de l'organisation fédérale les 45 espaces Sénégal Services dédiés à la formation et aux compétitions d'Esport.",
            l4: "La présence du groupe Orange Moyen-Orient Afrique, représenté par M. Issa Sene, Directeur du pôle Sponsoring pour la zone, qui était présent tout au long des débats.",
            l5: "Le Directeur Général de Smart Africa, M. Lancina Koné, et M. Amadou Gallo Fall, Président de la Basketball Africa League (BAL) et Vice-Président Principal de la NBA, ont délivré des messages de soutien aux participants.",
            l6: "Mathieu Faye, Vice-Président de la FIBA Africa et Président de la Zone Afrique de l'Ouest",
            l7: "Mayank Dhingra, Leader Commercial Senior pour l'Éducation Europe, Afrique et Moyen-Orient chez HP",
          },
          p6: "L'Assemblée Générale Constitutive pour la création d'une Confédération Panafricaine autour de l'Esport et de ses activités et professions connexes a été organisée sous l'égide de Son Excellence, M. Macky SALL, Président de la République du Sénégal, du Ministère de la Communication, des Télécommunications et de l'Économie Numérique de la République du Sénégal, et avec le parrainage du Groupe ADS, ainsi que par la « Fédération Sénégalaise des Jeux Électroniques et Sports Immersifs », actuellement « Comité National de Promotion du E-Sport » (CONAPES), sous l'égide du Ministère des Sports, et de plusieurs autres structures africaines légalement constituées (Fédérations, Comités, etc.).",
          p7: "Des institutions telles qu'Unesco, HP Education au Moyen-Orient et en Afrique, VISA, la FIBA Africa, le Conseil des Sports de l'Union Africaine, la Fédération Sénégalaise de Basketball, le Comité Olympique Sénégalais, ainsi que de grandes institutions mondiales d'Esport, dont plusieurs personnalités du monde des jeux vidéo, ont participé à l'événement.",
          p8: "Pour plus d'informations, veuillez contacter : M. Mamadou Kane au +221 77 894 13 58 / M. Boubacar Ba au +221 77 485 72 64 / M. Koffi LATZOO au +221 70 946 24 89",
        },
      },
      Communique: {
        title: "Communiqué ",
        part1: {
          p1: "Cameroon E-Sports vient de signer une convention avec EreaDrone, une Société française Partenaire  de la Fédération Internationale d'Aéronautique.",
          p2: "Les amateurs et professionnels des drones ont désormais leur competitions d'Esport à travers le e-Drone téléchargeable sur Stream.",
          p3: "Pour tous renseignements, veuillez contacter la Direction Technique  de la Cameroon E-Sports.",
          p4: "Blaise DIBOTO",
          p5: "Cameroon E-Sports President",
        },
      },
    },
  },
};
