import { useEffect, useState } from "react";
import { fetchAccessToken, fetchLiveStreams } from "../utils/action";

export const useLiveStreams = (adminChannels: string[]) => {
    const [liveChannels, setLiveChannels] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
  
    useEffect(() => {
      const fetchStreams = async () => {
        try {
          const token = await fetchAccessToken(process.env.CLIENT_ID as string, process.env.CLIENT_SECRET as string);
          const liveUsers = await fetchLiveStreams(adminChannels, token as string);
          setLiveChannels(liveUsers);
        } catch (err) {
          setError("Erreur lors de la récupération des streams en direct");
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchStreams();
    }, [adminChannels]);
  
    return { liveChannels, isLoading, error };
  };