import { useEffect, useMemo } from "react";
import AdminContainer from "../component/AdminContainer";
import "../styles/Dashboard.css";
import CardDashbord from "../component/cardDashbord";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";

import {
  DashBoardInfoState,
  getDashboardInfo,
} from "../redux/slices/dashboardSlice";
import CircularLoader from "../component/loader/CircularLoader";

interface Payment {
  payment_info:string
}

interface DashboardInfo {
  participantsCount: number;
  membersCount: number;
  cardsCount: number;
  payments: Payment[];
}

function DashboardPage() {
  const dispatch = useDispatch();

  const { data: dashboardInfo, loading, error } = useSelector<
    Record<string, DashBoardInfoState>,
    DashBoardInfoState
  >((state) => state.dashboard);

  useEffect(() => {
    dispatch(getDashboardInfo() as unknown as AnyAction);
  }, [dispatch]);

  const totalPayments = useMemo(() => {
    return dashboardInfo?.payments?.reduce((total: number, payment: Payment) => {
      try {
        const paymentInfo = JSON.parse(payment.payment_info);
        const amount = parseFloat(paymentInfo.amount);
        return isNaN(amount) ? total : total + amount;
      } catch (error) {
        console.error("Erreur lors du parsing du payment_info:", error);
        return total;
      }
    }, 0) ?? 0;
  }, [dashboardInfo]);

  return (
    <AdminContainer>
      <div className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-4 h-100">
        <h1 className="text-center text-primary">
          Tableau de bord de votre region
        </h1>
        {loading ? (
          <CircularLoader />
        ) : error ? (
          <p>Une erreur s'est produite : {error}</p>
        ) : dashboardInfo ? (
          <div className=" d-flex flex gap-4 justify-content-center align-items-center w-100 flex-wrap">
            <CardDashbord
              icon={<i className="fa fa-chart-line"></i>}
              title="Nombre de participants"
              description={dashboardInfo?.participantsCount}
            />
            <CardDashbord
              icon={<i className="fa fa-chart-line"></i>}
              title="Nombre de membre"
              description={dashboardInfo?.membersCount}
            />
            <CardDashbord
              icon={<i className="fa fa-chart-line"></i>}
              title="Cartes vendues"
              description={dashboardInfo?.cardsCount}
            />
            <CardDashbord
              icon={<i className="fa fa-chart-line"></i>}
              title="Revenus des cartes vendues"
              description={`FCFA ${totalPayments}`}
            />
          </div>
        ) : (
          <p>Aucune donnée disponible</p>
        )}
      </div>
    </AdminContainer>
  );
}

export default DashboardPage;
