import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { generateSlides } from "../../utils/genrateSlide";
import "../../styles/splide.css";

export const HomeRegion = () => {
  const [gap, setGap] = useState(5);
  const [perPage, setPerPage] = useState(4);
  const [height, setHeight] = useState(15);

  return (
    <section
      style={{ width: "100%" }}
      id="about"
      className="section-region d-flex flex-column justify-content-center align-items-center"
    >
      <div
        style={{ width: "85%" }}
        className="HomePage__about__content mb-5  fixe  d-flex flex-column p-3 mt-5 justify-content-between align-items-start"
      >
        <h2 className="text-primary">Region Esports</h2>

        <div className="d-flex justify-content-center align-items-center w-100">
          <Splide
            options={{
              perPage,
              height: `${height}rem`,
              gap,
              breakpoints: {
                1024: { perPage: 3, gap: "1rem" },
                768: { perPage: 2, gap: "0.5rem" },
                520: { perPage: 1, gap: "0.5rem", height: "20rem" },
              },
            }}
            aria-labelledby="cameroon esports regoins"
          >
            {generateSlides().map((slide) => (
              <SplideSlide key={slide.src}>
                <img src={slide.src} alt={slide.alt} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </section>
  );
};
