import { useRef } from "react";
import "../../styles/component/RegionDetail.css";

interface IRegionDetailPayment {
  title: string;
  totalAmount: string;
  totalPayment: string;
  onTransfert: (region: string) => void;
  loading: boolean;
}

const RegionDetail = ({
  title,
  totalAmount,
  totalPayment,
  onTransfert,
  loading = false,
}: IRegionDetailPayment) => {
  const transfertRef = useRef<HTMLButtonElement>(null);

  if (loading === true && transfertRef.current) {
    transfertRef.current.setAttribute("disabled", "true");
    transfertRef.current.classList.add("loading-button");
    transfertRef.current.innerHTML =
      '<div className="btn btn-primary loading-button><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true">Loading...</span></div>';
  }

  return (
    <div>
      <div className="card-region">
        <h2 className="card-region-title">{title}</h2>
        <hr />
        <div className="card-region-body">
          <p className="card-region-text">
            Total paiements : <span>{totalPayment}</span>
          </p>
          <p className="card-region-text">
            Revenue : <span>{totalAmount}</span>
          </p>
        </div>
        <button
          className="btn card-region-btn"
          onClick={() => onTransfert(title)}
          ref={transfertRef}
        >
          Transfert
        </button>
      </div>
    </div>
  );
};

export default RegionDetail;
