import React, { useEffect, useMemo } from "react";
import AdminContainer from "../component/AdminContainer";
import RegionDetail from "../component/payment/RegionDetail";
import { useDispatch, useSelector } from "react-redux";
import { addTransfert, getPaymentByRegion, RegionPayment } from "../redux/slices/transfertSlice";
import { AppDispatch, RootState } from "../redux/store";
import { toast } from "react-toastify";
import CircularLoader from "../component/loader/CircularLoader";

const TransfertPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { data: transfertData, loading, error } = useSelector((state: RootState) => state.transfert);

  const handleSubmit = (region: string) => {
    dispatch(addTransfert({ region }))
      .unwrap()
      .then(() => {
        toast.success("Transfert initiated");
        dispatch(getPaymentByRegion());
      })
      .catch((err: Error) => {
        console.error("Transfer error:", err);
        toast.error(`Something went wrong: ${err.message}`);
      });
  };

  useEffect(() => {
    dispatch(getPaymentByRegion());
  }, [dispatch]);

  const calculateRevenue = useMemo(() => (amount: number): number => {
    return (amount * 40) / 100;
  }, []);

  return (
    <AdminContainer>
      <div className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-4 h-100">
        <h1 className="text-primary">Transfert Page</h1>
        <div>
          {loading ? (
            <CircularLoader />
          ) : error ? (
            <div className="error">Error: {error}</div>
          ) : transfertData && transfertData.length > 0 ? (
            transfertData.map((region: RegionPayment) => (
              <RegionDetail
                key={region.id}
                title={region.region}
                totalAmount={`FCFA ${calculateRevenue(region.total_amount)}`}
                totalPayment={`FCFA ${region.total_amount}`}
                onTransfert={() => handleSubmit(region.region)}
                loading={loading}
              />
            ))
          ) : (
            <div className="no-data">
              <p>No regions have received payment yet.</p>
            </div>
          )}
        </div>
      </div>
    </AdminContainer>
  );
};

export default TransfertPage;
