export interface AdMedia {
  type: 'image' | 'video';
  url: string;
  alt?: string; // Pour les images
}

export interface AdLink {
  url: string;
  text: string;
}

export interface Ad {
  id: string;
  type: 'sidebar' | 'banner' | 'inStream';
  title: string;
  content: string;
  media?: AdMedia;
  link?: AdLink;
  duration?: number;
  lastShown?: number;
  clickCount: number;
}

class AdService {
  private ads: Ad[] = [
    {
      id: '1',
      type: 'sidebar',
      title: 'Publicité Sidebar',
      content: 'Découvrez le dernier jeu de stratégie en temps réel !',
      media: { type: 'image', url: 'https://example.com/game-ad.jpg', alt: 'Publicité 1' },
      link: { url: 'https://example.com/new-game', text: 'En savoir plus' },
      clickCount: 0
    },
    {
      id: '2',
      type: 'banner',
      title: 'Publicité Banner',
      content: 'Obtenez 50% de réduction sur votre abonnement annuel !',
      media: { type: 'video', url: 'https://example.com/ad2.mp4' },
      clickCount: 0
    },
    {
      id: '3',
      type: 'inStream',
      title: 'Publicité In-Stream',
      content: 'Contenu de la publicité in-stream...',
      link: { url: 'https://example.com', text: 'Visiter le site' },
      duration: 15,
      clickCount: 0
    },
  ];

  private adHistory: { [key: string]: number } = {};

  getAd(type: 'sidebar' | 'banner' | 'inStream'): Ad {
    const now = Date.now();
    const availableAds = this.ads.filter(ad => 
      ad.type === type && 
      (!this.adHistory[ad.id] || now - this.adHistory[ad.id] > 2 * 60 * 1000) // N'affiche pas la même publicité plus d'une fois toutes les 30 minutes
    );

    if (availableAds.length === 0) {
      // Si aucune publicité n'est disponible, réinitialiser l'historique
      this.adHistory = {};
      return this.getAd(type);
    }

    const ad = availableAds[Math.floor(Math.random() * availableAds.length)];
    this.adHistory[ad.id] = now;
    return ad;
  }

  trackAdClick(adId: string) {
    const ad = this.ads.find(a => a.id === adId);
    if (ad) {
      ad.clickCount++;
      console.log(`Ad clicked: ${ad.title}, Total clicks: ${ad.clickCount}`);
      // Ici, vous pourriez envoyer ces données à un serveur pour un suivi plus précis
    }
  }

  getAdWithStructure(type: 'sidebar' | 'banner' | 'inStream'): string {
    const ad = this.getAd(type);
    console.log("ad",ad);
    let mediaHtml = '';
    if (ad.media) {
      if (ad.media.type === 'image') {
        mediaHtml = `<img src="${ad.media.url}" alt="${ad.media.alt || ''}" className="ad-media">`;
      } else if (ad.media.type === 'video') {
        mediaHtml = `<video src="${ad.media.url}" className="ad-media" controls></video>`;
      }
    }

    let linkHtml = '';
    if (ad.link) {
      linkHtml = `<a href="${ad.link.url}" class="ad-link" target="_blank" rel="noopener noreferrer">${ad.link.text}</a>`;
    }

    switch (ad.type) {
      case 'sidebar':
      case 'banner':
        return `
          <div className="ad-container ${ad.type}">
            <div className="ad-title">${ad.title}</div>
            ${mediaHtml}
            <div className="ad-content">${ad.content}</div>
            ${linkHtml}
          </div>
        `;
      case 'inStream':
        return `
          <div className="ad-container ${ad.type}">
            <div className="ad-content">
              <div className="ad-title">${ad.title}</div>
              ${mediaHtml}
              <div>${ad.content}</div>
              ${linkHtml}
            </div>
            <button className="ad-close">&times;</button>
          </div>
        `;
      default:
        return '';
    }
  }
}

export const adService = new AdService();
