import { useEffect, useState } from "react";
import AdminContainer from "../component/AdminContainer";
import StripePaymentForm from "../component/form/PaymentForm";
import "../styles/page/CardPage.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CircularLoader from "../component/loader/CircularLoader";
import { UserState } from "../redux/slices/userSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { parseReadableDate } from "../utils/utils";
import { useTranslation } from "react-i18next";

const CardPage = () => {
  const userData = useSelector<Record<string, UserState>, UserState>(
    (state) => state.user
  ).data;
  const [onPay, setOnPay] = useState(false);
  const [cardPrice, setCardPrice] = useState(10000);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handlePaymentSuccess = () => {
    toast.success("Votre carte E-Sports gamer a bien été généré !", {
      autoClose: false,
    });
  };

  const handleGoToPayment = (e: any) => {
    if (!userData?.image) {
      return toast.warning(
        <div>
          Vous n'avez pas de photo de profil, veuillez uploader une photo dans
          la{" "}
          <span
            onClick={() => navigate("/dashboard/profile")}
            className="deeplink"
          >
            section profile{" "}
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </span>{" "}
          avant d'acheter votre carte e-sports.
        </div>,
        { autoClose: false }
      );
    }
    setOnPay(true);
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

  const cardExpiresAt = (date: Date | undefined) => {
    if (date !== undefined) {
      return parseReadableDate(
        new Date(new Date(date).getFullYear(), 11, 31),
        true,
        true
      );
      // return parseReadableDate(addOneYear(date), true, true);
    }
  };

  useEffect(() => {
    if (userData && userData.status === "Team") {
      setCardPrice(70000);
    }
  }, []);

  return (
    <AdminContainer>
      {!onPay && !userData?.certified && (
        <section className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-4 h-100">
          <h1 className="text-primary text-bold">{t("cardPage.title4")}</h1>
          <div className="mt-3">
            <div className="row p-0 m-0 mx-lg-4">
              <div className="joinImage col-lg-6 d-flex flex-column justify-content-start">
                <h2 className="text-light"> {t("advantages.title")}</h2>
                <ol>
                  {Array(4)
                    .fill(0)
                    .map((_, key) => {
                      return (
                        <div key={key}>
                          <li>{t(`advantages.t${key + 1}`)}</li>
                          <p>{t(`advantages.a${key + 1}`)}</p>
                        </div>
                      );
                    })}
                </ol>
              </div>
              <div className="col-lg-6 p-0 m-0 flex-column d-flex justify-content-center align-items-center">
                <div className="col-12 mx-auto d-flex gap-2 justify-content-center">
                  <img
                    className="col-6 shadow-lg"
                    alt=""
                    src={`${process.env.REACT_APP_SERVER_BASE_URL}assets/model-esports-recto.jpg`}
                  />
                  <img
                    className="col-6 shadow"
                    style={{ scale: "1" }}
                    alt=""
                    src={`${process.env.REACT_APP_SERVER_BASE_URL}assets/model-esports-verso.jpg`}
                  />
                </div>
                <button
                  onClick={handleGoToPayment}
                  type="submit"
                  className="esport mt-2 col-12 col-md-6 esport-btn-normal p-0"
                >
                  {t("Pay")} <i className="fa fa-shopping-cart" />
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          {onPay && !userData?.certified && (
            <StripePaymentForm
              onGoBack={() => setOnPay(false)}
              amount={cardPrice}
              title={t("PayCard")}
              stripe_fee={2.7}
              dataForPayment={{}}
              onPaymentSuccess={handlePaymentSuccess}
            />
          )}
        </Elements>
      ) : (
        <CircularLoader />
      )}

      {userData?.certified && userData.card && (
        <section className="HomePage__about__content bounce-animation w-100 d-flex flex-column rounded p-4 h-100">
          <h2 className="text-primary">{t("cardPage.title1")}</h2>
          <div className="col-lg-6 p-0 m-0 mx-auto flex-column d-flex justify-content-center align-items-center">
            <div className="col-8 mx-auto d-flex gap-2 justify-content-center">
              <img
                className="col-6 shadow-lg"
                alt=""
                src={userData.card.at(-1)?.image.recto}
              />
              <img
                className="col-6 shadow"
                style={{ scale: "1" }}
                alt=""
                src={userData.card.at(-1)?.image.verso}
              />
            </div>
            <div>
              <span className="small text-white-50 user-select-none">
                <i className="fa fa-calendar-check" /> {t("cardPage.title3")}{" "}
                {cardExpiresAt(userData.card?.at(-1)?.created_at)}
              </span>
            </div>
          </div>
          <h1 className="text-center">
            {t("cardPage.title2")} <span className="text-yellow">$100,000</span>{" "}
            {t("mainTitle.part3")}{" "}
          </h1>
        </section>
      )}
    </AdminContainer>
  );
};

export default CardPage;
